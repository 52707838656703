import React from 'react';
import { isEmpty, o } from 'ramda';
import { cx } from 'ramda-extension';
import { any, bool, func, node, object, oneOfType, string } from 'prop-types';
import { Box } from 'lundium';

import { withFormField, withGeneratedId } from '../../utils';

export const CheckboxField = ({
	id,
	generatedId,
	label,
	disabled,
	input,
	meta,
	type = 'checkbox',
	className,
	formGroupProps = {},
	inputRef,
	onChange,
	isSimple = false,
	...rest
}) => {
	const fieldId = id || generatedId;
	const isActive = meta?.active;

	return (
		<Box
			className={cx('form-group--checkbox', className, {
				'is-focused': isActive || (!isEmpty(input.value) && !isSimple),
				'table-list__item-checkbox form-group--checkbox-simple': isSimple,
				disabled,
			})}
			{...formGroupProps}
		>
			<input
				className="form-control"
				id={fieldId}
				type={type}
				ref={inputRef}
				checked={input.value === true}
				onChange={onChange}
				{...rest}
				{...input}
			/>
			<label className="control-label" htmlFor={fieldId}>
				{label}
			</label>
		</Box>
	);
};

CheckboxField.propTypes = {
	className: string,
	disabled: bool,
	formGroupProps: any,
	generatedId: string,
	id: string,
	input: any,
	inputRef: object,
	isSimple: bool,
	label: oneOfType([string, node, object]),
	meta: any,
	onChange: func,
	type: string,
};

export default o(withGeneratedId, withFormField)(CheckboxField);
