export const downloadFile = (content, filename) => {
	if (isIE()) {
		window.navigator.msSaveOrOpenBlob(content, filename);
	} else {
		const url = window.URL.createObjectURL(content);
		const downloadLink = document.createElement('a');
		downloadLink.setAttribute('href', url);
		downloadLink.setAttribute('download', filename);
		downloadLink.style.display = 'none';
		document.body.appendChild(downloadLink);
		downloadLink.click();
		window.URL.revokeObjectURL(url);
		document.body.removeChild(downloadLink);
	}
};

const isIE = () => window.navigator && window.navigator.msSaveOrOpenBlob;
