import { object, shape, string } from 'prop-types';
import { getLocaleDate } from '@uamk/utils';

const TableCellDate = ({ row: { values }, column: { id } }) => getLocaleDate(values[id]);

TableCellDate.propTypes = {
	column: shape({ id: string }),
	row: shape({ values: object }),
};

export default TableCellDate;
