import React, { useEffect } from 'react';
import { bool, func, node, number, object, oneOf, oneOfType, string } from 'prop-types';
import { Box, Button, Icon } from 'lundium';
import { values } from 'ramda';
import { cx, noop } from 'ramda-extension';

import { type } from '../constants';

const Notification = ({
	id,
	type = type.INFO,
	hideAfter = 5000,
	labelAction,
	onClickAction = noop,
	closeNotification,
	hasLongBtnLabel = false,
	children,
	showCloseBtn = true,
}) => {
	useEffect(() => {
		const timer = hideAfter > 0 && setTimeout(closeNotification, hideAfter);

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [closeNotification, hideAfter]);

	const handleAction = () => {
		onClickAction(id);
		closeNotification();
	};

	return (
		<Box
			key={id}
			className={cx(`notification notification--${type}`, {
				'notification--wrapped': hasLongBtnLabel,
			})}
		>
			<Box className="notification__content">{children}</Box>
			{showCloseBtn && (
				<Box className="notification__btn-holder">
					<Button kind="blank" onClick={closeNotification} p={0} pl={{ xs: 3, sm: 4 }}>
						<Icon type="close" color="white" />
					</Button>
				</Box>
			)}
			{labelAction && (
				<Box className="notification__btn-holder">
					<Button kind="blank" onClick={handleAction} p={0} pl={{ xs: 3, sm: 4 }}>
						{labelAction}
					</Button>
				</Box>
			)}
		</Box>
	);
};

Notification.propTypes = {
	children: node.isRequired,
	closeNotification: func,
	hasLongBtnLabel: bool,
	hideAfter: number,
	id: string.isRequired,
	labelAction: oneOfType([string, node, object]),
	onClickAction: func,
	showCloseBtn: bool,
	type: oneOf(values(type)),
};

export default Notification;
