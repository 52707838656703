import React from 'react';
import { func } from 'prop-types';
import { Box } from 'lundium';

import TableCheckbox from '../TableCheckbox';

const TableHeaderCheckbox = ({ getToggleAllRowsSelectedProps }) => (
	<Box className="d-flex align-items-center">
		<Box
			className="form-group--checkbox form-group--checkbox-label-color-inherit form-group--checkbox-table"
			my={0}
		>
			<TableCheckbox {...getToggleAllRowsSelectedProps()} />
		</Box>
	</Box>
);

TableHeaderCheckbox.propTypes = {
	getToggleAllRowsSelectedProps: func,
};

export default TableHeaderCheckbox;
