import React from 'react';
import {
	composeMiddleware,
	makeActionTypes,
	makeEmptyActionCreator,
	makePayloadActionCreator,
	makeReducer,
} from '@redux-tools/react';
import { addNotification, removeAllNotifications, type } from '@uamk/notifications';
import { isInBrowser, makeMiddleware, typeEq } from '@uamk/utils';
import { persist, purge } from '@uamk/persistor';
import { configure as apiumConfigure, isAnyApiumRequest } from 'apium';
import { T, always, cond, equals, forEach } from 'ramda';
import { isNilOrEmpty, isNotEmpty } from 'ramda-extension';
import { FormattedMessage } from 'react-intl';

import { APP, Roles, baseUrl, defaultAuthAPIParams } from '../constants';
import m from '../messages';
import { getTokenData, isTokenExpired } from '../utils';

const ActionTypes = makeActionTypes('@auth', [
	'LOGIN',
	'FETCH_TOKEN',
	'FETCH_TOKEN_SUCCESS',
	'FETCH_TOKEN_ERROR',
	'REFRESH_TOKEN',
	'REVOKE_TOKEN',
	'STORE',
	'CLEAR_STATE',
	'CLEAN_UP',
	'BUFFERING_ACTION',
	'FLUSHING_ACTION',
]);

export const login = makePayloadActionCreator(ActionTypes.LOGIN);
export const fetchToken = makePayloadActionCreator(ActionTypes.FETCH_TOKEN);
export const fetchTokenSuccess = makePayloadActionCreator(ActionTypes.FETCH_TOKEN_SUCCESS);
export const fetchTokenError = makePayloadActionCreator(ActionTypes.FETCH_TOKEN_ERROR);
export const refreshToken = makeEmptyActionCreator(ActionTypes.REFRESH_TOKEN);
export const revokeToken = makeEmptyActionCreator(ActionTypes.REVOKE_TOKEN);
export const updateState = makePayloadActionCreator(ActionTypes.STORE);
export const clearState = makeEmptyActionCreator(ActionTypes.CLEAR_STATE);
export const cleanUp = makePayloadActionCreator(ActionTypes.CLEAN_UP);

export const bufferingEvent = makePayloadActionCreator(ActionTypes.BUFFERING_ACTION);
export const flushingEvent = makePayloadActionCreator(ActionTypes.FLUSHING_ACTION);

export const getToken = ({ auth }) => auth?.accessToken;
export const getRefreshToken = ({ auth }) => auth?.refreshToken;
export const isTokenFetchingPending = ({ auth }) => auth?.tokenFetchingPending;
export const getUserName = ({ auth }) => auth?.username;
export const getUserId = ({ auth }) => auth?.id;
export const getUserAuthorities = ({ auth }) => auth?.authorities;
export const getUserPartnerId = ({ auth }) => auth?.partnerId;
export const getUserPartnerName = ({ auth }) => auth?.partnerName;
export const hasChangePasswordAuthority = ({ auth }) =>
	auth?.authorities?.includes(Roles.CHANGE_PASSWORD_REQUIRED);
export const hasAdminAuthority = ({ auth }) => auth?.authorities?.includes(Roles.ADMIN);
export const hasDispatcherAuthority = ({ auth }) => auth?.authorities?.includes(Roles.DISPATCHER);
export const hasDriverAuthority = ({ auth }) => auth?.authorities?.includes(Roles.DRIVER);
export const hasClientAuthority = ({ auth }) => auth?.authorities?.includes(Roles.CLIENT);
export const isAuthenticated = (state) => !isNilOrEmpty(getToken(state));
export const isAccessTokenExpired = (state) => isTokenExpired(getToken(state));
export const isAuthorized = (state) =>
	cond([
		[equals('partner-portal'), () => hasAdminAuthority(state) || hasDispatcherAuthority(state)],
		[T, always(false)],
	])(APP);

const initialState = {
	tokenFetchingPending: false,
};

const authAPI = `${baseUrl}/oauth/token`;

export const makeAuthenticationMiddleware = () => {
	const bufferedRequests = [];

	/**
	 * Procedure: adds a request to the buffer.
	 *
	 * @param {Object} reduxAPI the Redux API
	 * @param {Action} request request action to buffer
	 */
	const bufferRequest = (reduxAPI, request) => {
		const { dispatch } = reduxAPI;

		dispatch(bufferingEvent(request));
		bufferedRequests.push(request);
	};

	const requestMiddleware = (reduxAPI) => (next) => (action) => {
		if (!isAnyApiumRequest(action)) {
			return next(action);
		}

		const { dispatch, getState } = reduxAPI;
		const state = getState();

		const token = getToken(state);

		if (isTokenFetchingPending(state)) {
			return bufferRequest(reduxAPI, action);
		}

		if (token) {
			if (isTokenExpired(token)) {
				bufferRequest(reduxAPI, action);

				return dispatch(refreshToken());
			}

			return next(action);
		}

		return next(action);
	};

	const loginMiddleware = makeMiddleware(
		typeEq(ActionTypes.LOGIN),
		({ dispatch }) => async ({ payload }) => {
			dispatch(fetchToken({ grant_type: 'password', ...payload }));
		}
	);

	const fetchTokenMiddleware = makeMiddleware(
		typeEq(ActionTypes.FETCH_TOKEN),
		({ dispatch }) => async ({ payload }) => {
			dispatch(updateState({ tokenFetchingPending: true }));

			try {
				const response = await fetch(authAPI, {
					method: 'POST',
					body: new URLSearchParams({
						...defaultAuthAPIParams,
						...payload,
					}).toString(),
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				});
				const data = await response.json();

				if (response.ok) {
					return dispatch(fetchTokenSuccess(data));
				} else {
					return dispatch(fetchTokenError(data));
				}
			} catch (error) {
				return dispatch(fetchTokenError(error));
			}
		}
	);

	const refreshTokenMiddleware = makeMiddleware(
		typeEq(ActionTypes.REFRESH_TOKEN),
		({ dispatch, getState }) => async () => {
			if (isTokenFetchingPending(getState())) {
				return;
			}
			const refreshToken = getRefreshToken(getState());
			if (!refreshToken || isTokenExpired(refreshToken)) {
				return dispatch(fetchTokenError({ detail: <FormattedMessage {...m.cannotRefresh} /> }));
			}

			dispatch(fetchToken({ grant_type: 'refresh_token', refresh_token: refreshToken }));
		}
	);

	const revokeTokenMiddleware = makeMiddleware(
		typeEq(ActionTypes.REVOKE_TOKEN),
		({ dispatch, getState }) => async () => {
			const refreshToken = getRefreshToken(getState());
			const token = getToken(getState());

			await fetch(`${authAPI}/revoke`, {
				method: 'DELETE',
				body: new URLSearchParams({
					token: refreshToken,
				}),
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization: `Bearer ${token}`,
				},
			});

			dispatch(cleanUp({ clearNotifications: true }));
		}
	);

	/**
	 * Middleware for flushing buffered requests when a new access token is available.
	 */
	const fetchTokenSuccessMiddleware = makeMiddleware(
		typeEq(ActionTypes.FETCH_TOKEN_SUCCESS),
		({ dispatch }) => ({ payload }) => {
			const { access_token, refresh_token } = payload;

			if (!access_token || isTokenExpired(access_token)) {
				return dispatch(fetchTokenError({ detail: <FormattedMessage {...m.tokenInvalid} /> }));
			}

			const { user_name, ...otherData } = getTokenData(access_token);
			dispatch(apiumConfigure({ baseHeaders: { Authorization: `Bearer ${access_token}` } }));
			dispatch(
				updateState({
					tokenFetchingPending: false,
					accessToken: access_token,
					refreshToken: refresh_token,
					username: user_name,
					...otherData,
				})
			);
			dispatch(persist());

			if (isNotEmpty(bufferedRequests)) {
				const flushedRequests = [];

				// NOTE: We copy and clear the array to avoid infinite loops.
				while (isNotEmpty(bufferedRequests)) {
					flushedRequests.push(bufferedRequests.shift());
				}

				dispatch(flushingEvent(flushedRequests));
				forEach((request) => dispatch(request), flushedRequests);
			}
		}
	);

	const fetchTokenErrorMiddleware = makeMiddleware(
		typeEq(ActionTypes.FETCH_TOKEN_ERROR),
		({ dispatch }) => ({ payload }) => {
			if (payload?.errorCode === 'invalid_grant') {
				dispatch(
					addNotification({ message: <FormattedMessage {...m.invalidGrant} />, type: type.ERROR })
				);
			} else if (payload?.errorCode === 'NOT-FOUND') {
				dispatch(
					addNotification({ message: <FormattedMessage {...m.notFound} />, type: type.ERROR })
				);
			} else if (payload?.errorCode === 'BAD-CREDENTIALS') {
				dispatch(
					addNotification({ message: <FormattedMessage {...m.badCredentials} />, type: type.ERROR })
				);
			} else {
				dispatch(
					addNotification({
						message: payload?.detail || <FormattedMessage {...m.apiError} />,
						type: type.ERROR,
					})
				);
			}

			dispatch(cleanUp({ clearNotifications: false }));
		}
	);

	const cleanUpMiddleware = makeMiddleware(
		typeEq(ActionTypes.CLEAN_UP),
		({ dispatch }) => ({ payload: { clearNotifications } }) => {
			dispatch(apiumConfigure({ baseHeaders: undefined }));
			dispatch(clearState());
			dispatch(purge());
			if (isInBrowser() && clearNotifications) {
				dispatch(removeAllNotifications());
			}
			bufferedRequests.length = 0;
		}
	);

	return composeMiddleware(
		requestMiddleware,
		loginMiddleware,
		fetchTokenMiddleware,
		fetchTokenSuccessMiddleware,
		fetchTokenErrorMiddleware,
		refreshTokenMiddleware,
		revokeTokenMiddleware,
		cleanUpMiddleware
	);
};

export default makeReducer(
	[
		[ActionTypes.STORE, (state, { payload }) => ({ ...state, ...payload })],
		[ActionTypes.CLEAR_STATE, always(initialState)],
	],
	initialState
);
