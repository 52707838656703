import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { func, node, number, shape, string } from 'prop-types';
import { Box } from 'lundium';
import { useHistory } from 'react-router-dom';

import m from '../../messages';
import OutsideClickBoundary from '../OutsideClickBoundary';
import useIsGtThenViewport from '../../hooks';

const NavSecondary = ({
	name,
	logout,
	onProfileClick,
	notifications: Notifications,
	notificationProps: { unreadCount, notificationPath, ...notificationProps } = {},
}) => {
	const [openNotifications, setOpenNotifications] = useState(false);
	const history = useHistory();
	const isLgViewport = useIsGtThenViewport();

	const handleNotificationClick = isLgViewport
		? () => setOpenNotifications(!openNotifications)
		: () => history.push(notificationPath);

	return (
		<Box as="nav" className="nav nav--secondary">
			<Box as="ul" className="nav__list">
				<Box as="li" className="nav__list-item" onClick={handleNotificationClick}>
					<Box as="span" className="icon icon--type-notification" />
					{unreadCount ? (
						<Box as="span" className="nav__list-item-badge">
							{unreadCount}
						</Box>
					) : null}
					<Box as="span" className="nav__list-item-label">
						<FormattedMessage {...m.notifications} />
					</Box>

					<OutsideClickBoundary onOutsideClick={() => setOpenNotifications(false)}>
						{openNotifications && <Notifications {...notificationProps} />}
					</OutsideClickBoundary>
				</Box>
				<Box as="li" className="nav__list-item" onClick={onProfileClick}>
					<Box as="span" className="icon icon--type-user" />
					<Box as="span" className="nav__list-item-label">
						{name}
					</Box>
				</Box>
				<Box as="li" className="nav__list-item" onClick={logout}>
					<Box as="span" className="icon icon--type-exit" />
					<Box as="span" className="nav__list-item-label">
						<FormattedMessage {...m.logout} />
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

NavSecondary.propTypes = {
	logout: func,
	name: string,
	notificationProps: shape({
		unreadCount: number,
		notificationPath: string,
	}),
	notifications: node,
	notificationsCount: number,
	onNotificationsClick: func,
	onProfileClick: func,
};

export default NavSecondary;
