const radioStepTwoValues = {
	yes: 'true',
	no: 'false',
	notSpecified: 'notSpecified',
};

export const cleanDataObjectIncidents = (object) => {
	Object.entries(object).forEach(([k, v]) => {
		if (v && typeof v === 'object') {
			cleanDataObjectIncidents(v);
		}
		if (
			(v && typeof v === 'object' && !Object.keys(v).length) ||
			v === 0 ||
			v === '' ||
			v === null ||
			v === NaN ||
			v === radioStepTwoValues.notSpecified
		) {
			delete object[k];
		}
	});

	return object;
};
