import React from 'react';
import { Box } from 'lundium';
import { arrayOf, func, number } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import m from '../../messages';

const TablePageSizeSelect = ({ pageSize, pageSizeOptions = [], setPageSize }) => (
	<Box className="table__pagination-entries-select">
		<Box
			as="select"
			className="mr-2"
			value={pageSize}
			onChange={(e) => {
				setPageSize(e.target.value);
			}}
		>
			{pageSizeOptions.map((pageSize) => (
				<Box as="option" key={pageSize} value={pageSize}>
					{pageSize}
				</Box>
			))}
		</Box>
		<FormattedMessage {...m.perPage} />
	</Box>
);

TablePageSizeSelect.propTypes = {
	pageSize: number,
	pageSizeOptions: arrayOf(number),
	setPageSize: func,
};

export default TablePageSizeSelect;
