import React from 'react';
import { node } from 'prop-types';
import { cx, noop } from 'ramda-extension';
import { Box, Icon } from 'lundium';

import modalWrapperPropTypes from './constants';

/**
 * Modal component wrapper
 * @param children - Content of modal dialog
 * @param isActive - Is current modal visible
 * @param actions - Redux actions for modal management (see @shared/modals library)
 */
const ModalLarge = ({ children, isActive, actions: { closeModal = noop } }) => (
	<Box className={cx('modal modal--lg fade', { show: isActive, 'modal--open': isActive })}>
		<Box className="modal-dialog">
			<Box className="modal-content">
				<Box className="modal-header">
					<Box onClick={() => closeModal()}>
						<Icon type="close" className="close" color="primary" />
					</Box>
				</Box>
				<Box className="modal-body p-0">
					<Box className="modal-body-inner">{children}</Box>
				</Box>
			</Box>
		</Box>
		<Box
			className={cx('backdrop--clickable', {
				'd-none': !isActive,
				'backdrop--open': isActive,
			})}
			onClick={(e) => {
				e.stopPropagation();
				closeModal();
			}}
		/>
	</Box>
);

ModalLarge.propTypes = {
	children: node,
	...modalWrapperPropTypes,
};

export default ModalLarge;
