import { format, isDate, parseISO } from 'date-fns';
import cs from 'date-fns/locale/cs';

import { isValidDate } from './isValidDate';

const DATE_FORMAT = 'd. M. yyyy';
const DATETIME_FORMAT = 'd. M. yyyy H:mm';
const HOUR_FORMAT = 'H';
const MINUTE_FORMAT = 'mm';

const formatISO = (value, formatPattern) => {
	const date = isDate(value) ? value : parseISO(value);

	return isValidDate(date) ? format(date, formatPattern, { locale: cs }) : '';
};

export const getLocaleDateTime = (value) => formatISO(value, DATETIME_FORMAT);

export const getLocaleDate = (value) => formatISO(value, DATE_FORMAT);

// Return string of today's date formatted as DATE_FORMAT = 'd. M. yyyy'
export const getTodayDate = () => format(new Date(), DATE_FORMAT, { locale: cs });
export const getActualHour = () => format(new Date(), HOUR_FORMAT, { locale: cs });
export const getActualMinutes = () => format(new Date(), MINUTE_FORMAT, { locale: cs });
