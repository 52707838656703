import { isNil, toString } from 'ramda';
import { isBoolean } from 'ramda-extension';

const boolObject = {
	true: true,
	false: false,
};

/*
if value === 'true' string, then return Boolean type true.
if value === 'false' string, then return Boolean type false.
if value === 'undefined' string, then return undefined value.
otherwise return same value.
*/
export const stringToBool = (value) =>
	boolObject[value] ?? (value === 'undefined' ? undefined : value);

export const boolToString = (value) =>
	isNil(value) ? 'undefined' : isBoolean(value) ? toString(value) : value;
