import React from 'react';
import { bool, node } from 'prop-types';
import { cx } from 'ramda-extension';
import { Box } from 'lundium';
import { FormattedMessage } from 'react-intl';

import m from '../../messages';

const Loader = ({ isActive, isTableLoader = true, children }) => (
	<Box
		className={cx('spinner', {
			'spinner--show-table-header': isTableLoader,
			'spinner--active': isActive,
		})}
	>
		<Box className="spinner__loader">
			<FormattedMessage {...m.loading} />
		</Box>
		{children}
	</Box>
);

Loader.propTypes = {
	children: node,
	isActive: bool,
	isTableLoader: bool,
};

export default Loader;
