import { defineMessages } from 'react-intl';

export default defineMessages({
	fetchNotificationError: {
		id: 'notifications.fetchNotificationError',
		defaultMessage: 'Sorry, something went wrong. Try again or contact your administrator.',
	},
	notificationDeleted: {
		id: 'notifications.notificationDeleted',
		defaultMessage: 'Notification message deleted',
	},
	notificationsDeleted: {
		id: 'notifications.notificationsDeleted',
		defaultMessage: 'Notification messages were deleted',
	},
	allNotificationsDeleted: {
		id: 'notifications.allNotificationsDeleted',
		defaultMessage: 'All notifications messages were deleted',
	},
});
