import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { cx } from 'ramda-extension';
import { Box, Icon } from 'lundium';
import { NavLink } from 'react-router-dom';

const NavPrimary = ({ items }) => (
	<Box as="nav" className="nav nav--primary">
		<Box className="nav__list">
			{items.map(({ icon, label, path }) => (
				<NavLink
					key={`${icon}-${label}`}
					activeClassName="nav__list-item--active"
					className={cx('nav__list-item')}
					to={path}
				>
					<Icon type={icon} color="white" />
					<Box as="span" className="nav__list-item-label">
						{label}
					</Box>
				</NavLink>
			))}
		</Box>
	</Box>
);

NavPrimary.propTypes = {
	items: arrayOf(
		shape({
			icon: string,
			label: string,
			path: string,
		})
	),
};

export default NavPrimary;
