import { reduce } from 'ramda';

export const resolveMessages = (translations = [], language, defaultLanguage) =>
	reduce(
		(acc, translationsModule) => {
			const moduleMessages =
				language in translationsModule
					? translationsModule[language]
					: translationsModule[defaultLanguage];

			return { ...acc, ...moduleMessages };
		},
		{},
		translations
	);
