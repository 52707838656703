import React from 'react';
import { arrayOf, func, number } from 'prop-types';
import { Box } from 'lundium';
import { cx, isString, noop } from 'ramda-extension';
import { FormattedMessage } from 'react-intl';
import { clamp } from 'ramda';

import { makePaginationDescriptor } from './makePaginationDescriptor';
import TablePageSizeSelect from '../TablePageSizeSelect';
import { apiPaginationShape, defaultPageSizeOptions } from '../../constants';
import m from '../../messages';

/**
 * Display pagination navigation component to navigate throw
 * @param pagging - It is an object which contains next values.
 * @param pagging.pageNumber - Number of the current page, start in zero.
 * @param pagging.pageCount - Number of all availabe pages, This count does not start in zero.
 * @param pagging.pageSize - Maximun number of items per page.
 * @param pagging.totalCount - Number of all records or items no matter the pagination.
 * @param setPage - Function to update the current page pageNumber.
 * @param setPageSize - Function to set the Maximun number of items per page.
 * @param pageSizeOptions - Array of integers with available options for Maximun items per page.
 */
const TablePagination = ({
	paging: { pageNumber: rawPageNumber = 0, pageCount: rawPageCount = 1, totalCount, pageSize },
	setPage,
	setPageSize,
	pageSizeOptions = defaultPageSizeOptions,
}) => {
	const pageCount = rawPageCount <= 0 ? 1 : rawPageCount;
	const activePage = clamp(1, pageCount, rawPageNumber + 1);
	const pages = Math.ceil(totalCount / pageSize);

	const paginationDescriptor = makePaginationDescriptor(activePage, pageCount);

	const goToPreviouPage = () => {
		if (activePage > 1) {
			setPage(rawPageNumber - 1);
		}
	};

	const goToNextPage = () => {
		if (activePage < pages) {
			setPage(rawPageNumber + 1);
		}
	};

	return (
		<Box className="table__pagination">
			<Box className="table__pagination-entries">
				<FormattedMessage
					{...m.entries}
					values={{
						pageSize: totalCount < pageSize ? totalCount : pageSize,
						totalRecords: totalCount,
					}}
				/>
			</Box>
			<TablePageSizeSelect
				pageSize={pageSize}
				setPageSize={setPageSize}
				pageSizeOptions={pageSizeOptions}
			/>
			<Box as="nav" aria-label="Page navigation" className="table__pagination-nav">
				<Box as="ul" className="pagination">
					<Box as="li" className="page-item">
						<Box as="span" className="page-link" aria-label="First">
							<Box
								as="span"
								aria-hidden="true"
								className="icon icon--type-arrow_first"
								onClick={() => setPage(0)}
							/>
						</Box>
					</Box>
					<Box as="li" className="page-item">
						<Box as="span" className="page-link" aria-label="Previous" onClick={goToPreviouPage}>
							<Box as="span" aria-hidden="true" className="icon icon--type-arrow_prev" />
						</Box>
					</Box>
					{paginationDescriptor.map((currentPageNumber) => {
						const isEllipsis = isString(currentPageNumber);
						const active = currentPageNumber === activePage;

						return (
							<Box as="li" key={currentPageNumber} className={cx('page-item', { active })}>
								<Box
									as="span"
									className="page-link"
									onClick={isEllipsis || active ? noop : () => setPage(currentPageNumber - 1)}
								>
									{isEllipsis ? '…' : currentPageNumber}
								</Box>
							</Box>
						);
					})}
					<Box as="li" className="page-item">
						<Box as="span" className="page-link" aria-label="Next">
							<Box
								as="span"
								aria-hidden="true"
								className="icon icon--type-arrow_next"
								onClick={goToNextPage}
							/>
						</Box>
					</Box>
					<Box as="li" className="page-item">
						<Box as="span" className="page-link" aria-label="Last">
							<Box
								as="span"
								aria-hidden="true"
								className="icon icon--type-arrow_last"
								onClick={() => setPage(pageCount - 1)}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

TablePagination.propTypes = {
	pageSizeOptions: arrayOf(number),
	paging: apiPaginationShape,
	setPage: func,
	setPageSize: func,
};

export default TablePagination;
