import React, { Fragment, useEffect } from 'react';
import { func, object, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { clear, getRedirectLocation } from './duck';

const RedirectComponent = ({ navigation: { path, state } }) => {
	const history = useHistory();

	useEffect(() => {
		if (path) {
			history.push(path, state);
			clear();
		}
	}, [path, state, history]);

	return <Fragment />;
};

RedirectComponent.propTypes = {
	clear: func,
	navigation: shape({ path: string, state: object }),
};

export default connect((state) => ({ navigation: getRedirectLocation(state) }), { clear })(
	RedirectComponent
);
