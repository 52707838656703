import {
	makeActionTypes,
	makeEmptyActionCreator,
	makePayloadActionCreator,
	makeReducer,
} from '@redux-tools/react';

const ActionTypes = makeActionTypes('@navigation', ['NAVIGATE', 'CLEAR']);

export const navigate = makePayloadActionCreator(ActionTypes.NAVIGATE);
export const clear = makeEmptyActionCreator(ActionTypes.CLEAR);

export const getRedirectLocation = ({ navigation }) => navigation;

const initialState = {};

export default makeReducer(
	[
		[ActionTypes.NAVIGATE, (state, { payload }) => payload],
		[ActionTypes.CLEAR, () => initialState],
	],
	initialState
);
