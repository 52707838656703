import { compose, map, reverse, split, trim } from 'ramda';

const getDateAdjustMonths = (year, month, ...rest) => new Date(year, Number(month) - 1, ...rest);

const sortDateValues = compose(map(trim), reverse, split(/[\.|/]/));

/**
 * Converts a string date in dd.mm.yyyy or dd/mm/yyyy format into a Date type
 * @param {string} date - required date in format dd.mm.yyyy or dd/mm/yyyy
 * @param {string || number} hours - optional defaults to 00
 * @param {string || number} minutes - optional defaults to 00
 * @returns Date
 */
export const getDate = (date = '', hours = '00', minutes = '00') => {
	// IE11 fix
	const splittedDate = sortDateValues(date);

	if (splittedDate.length < 3) {
		throw new Error(
			`date does not have proper format ${date} it should be dd.mm.yyyy or dd/mm/yyyy`
		);
	}

	return getDateAdjustMonths(...splittedDate, hours, minutes);
};

/**
 * Converts a string date in dd.mm.yyyy or dd/mm/yyyy format into ISO string format with timezone
 * @param {string} date - required date in format dd.mm.yyyy or dd/mm/yyyy
 * @param {string || number} hours - optional defaults to 00
 * @param {string || number} minutes - optional defaults to 00
 * @returns string
 */
export const getDateISOString = (...args) => getDate(...args).toISOString();
