import React from 'react';
import { Box } from 'lundium';
import { arrayOf, shape, string } from 'prop-types';

import RadioField from '../RadioField';

const RadioGroup = ({ input: { name, ...input }, label, options }) => (
	<Box className="radio-group">
		<Box className="radio-group__label">{label}</Box>
		<Box className="radio-group__fields">
			{options.map(({ label, value }) => (
				<RadioField {...{ input, value, label }} key={`${name}-${value}`} />
			))}
		</Box>
	</Box>
);

RadioGroup.propTypes = {
	input: shape({ name: string }),
	label: string,
	options: arrayOf(shape({ label: string, value: string })),
};

export default RadioGroup;
