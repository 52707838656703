import { always, propEq, reject } from 'ramda';
import {
	makeActionTypes,
	makeEmptyActionCreator,
	makePayloadActionCreator,
	makeReducer,
} from '@redux-tools/react';
import { generateId } from '@uamk/utils';

export const ActionTypes = makeActionTypes('@notifications', ['ADD', 'REMOVE', 'REMOVE_ALL']);

export const addNotification = makePayloadActionCreator(ActionTypes.ADD);
export const removeNotification = makePayloadActionCreator(ActionTypes.REMOVE);
export const removeAllNotifications = makeEmptyActionCreator(ActionTypes.REMOVE_ALL);

export const getAllNotifications = ({ notifications }) => notifications;

const initialState = [];

export default makeReducer(
	[
		[
			ActionTypes.ADD,
			(state, { payload: { id, ...rest } }) => [{ id: id ?? generateId(), ...rest }, ...state],
		],
		[ActionTypes.REMOVE, (state, { payload }) => reject(propEq('id', payload), state)],
		[ActionTypes.REMOVE_ALL, always(initialState)],
	],
	initialState
);
