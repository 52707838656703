import React, { Fragment, forwardRef, useEffect, useRef } from 'react';
import { bool, string } from 'prop-types';

import { withGeneratedId } from '../../utils';

const TableCheckbox = forwardRef(({ indeterminate, generatedId, ...rest }, ref) => {
	const defaultRef = useRef();
	const resolvedRef = ref || defaultRef;

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return (
		<Fragment>
			<input
				type="checkbox"
				className="form-control"
				id={generatedId}
				ref={resolvedRef}
				{...rest}
			/>
			<label className="control-label" htmlFor={generatedId} />
		</Fragment>
	);
});

TableCheckbox.displayName = 'TableCheckbox';

TableCheckbox.propTypes = {
	generatedId: string,
	indeterminate: bool,
};

export default withGeneratedId(TableCheckbox);
