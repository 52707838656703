import decodeToken from 'jwt-decode';
import { compose, o, pick, prop } from 'ramda';

import { jwtAttributes } from './constants';

const isHistoricalTimestamp = (exp) => exp * 1000 < Date.now();

export const isTokenExpired = (token) =>
	token && compose(isHistoricalTimestamp, prop('exp'), decodeToken)(token);

export const getTokenData = o(pick(jwtAttributes), decodeToken);
