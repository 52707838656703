import React from 'react';
import { node } from 'prop-types';
import { Box } from 'lundium';

const DataList = ({ label = '', value = '' }) => (
	<Box as="span">
		<Box as="dt" className="params-list__label">
			{label}
		</Box>
		<Box as="dd" className="params-list__value">
			{value}
		</Box>
	</Box>
);

DataList.propTypes = {
	label: node,
	value: node,
};

export default DataList;
