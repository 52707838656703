import { isValidDate } from './isValidDate';

const getValidTime = (date) => (isValidDate(date) ? date.getTime() : 0);

/**
 * Function returns date difference in milliseconds. Primary intended to be used ti get input value for CountDown components.
 * @param {Date} end date
 * @param {Date} start date
 * @returns date diff in milliseconds
 */
export const getTimeDiffMillis = (end, start = new Date()) =>
	getValidTime(end) - getValidTime(start);
