import React, { useMemo, useState } from 'react';
import { Box, Button, Grid, Icon } from 'lundium';
import Lightbox from 'react-image-lightbox';
import { arrayOf, shape, string } from 'prop-types';

const LightboxGallery = ({ items = [] }) => {
	const [startIndex, setStartIndex] = useState(0);
	const [isOpen, setIsOpen] = useState(false);
	const memoizedItems = useMemo(() => items.map(({ url }) => url), [items]);
	const itemsLength = memoizedItems.length;

	return (
		<Grid container>
			<Grid row>
				{memoizedItems.map((item, index) => (
					<Grid col={{ xs: 12, sm: 6, lg: 4 }} mb={3} key={`img-gallery-item-${index}`}>
						<Box className="image-gallery__prev-img-wrapper">
							<img src={item} className="image-gallery__prev-img" />
							<Button
								className="image-gallery__prev-img-btn"
								onClick={() => {
									setStartIndex(index);
									setIsOpen(true);
								}}
							>
								<Icon type="extend" color="inherit" />
							</Button>
						</Box>
					</Grid>
				))}
				{isOpen && (
					<Lightbox
						mainSrc={memoizedItems[startIndex]}
						nextSrc={memoizedItems[(startIndex + 1) % itemsLength]}
						prevSrc={memoizedItems[(startIndex + itemsLength - 1) % itemsLength]}
						onCloseRequest={() => setIsOpen(false)}
						onMovePrevRequest={() => setStartIndex((startIndex + itemsLength - 1) % itemsLength)}
						onMoveNextRequest={() => setStartIndex((startIndex + 1) % itemsLength)}
						reactModalStyle={{ overlay: { zIndex: 1050 } }}
					/>
				)}
			</Grid>
		</Grid>
	);
};

LightboxGallery.propTypes = {
	items: arrayOf(
		shape({
			id: string,
			url: string,
			createdAt: string,
			createdBy: string,
		})
	),
};

export default LightboxGallery;
