export const cleanDataObject = (object) => {
	Object.entries(object).forEach(([k, v]) => {
		if (v && typeof v === 'object') {
			cleanDataObject(v);
		}
		if ((v && typeof v === 'object' && !Object.keys(v).length) || v === '' || v === null) {
			delete object[k];
		}
	});

	return object;
};
