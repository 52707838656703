import React from 'react';
import { isEmpty, o } from 'ramda';
import { cx } from 'ramda-extension';
import { any, bool, element, node, object, oneOfType, string } from 'prop-types';
import { Box } from 'lundium';
import { FormattedMessage } from 'react-intl';

import { withFormField, withGeneratedId } from '../../utils';

export const InputField = ({
	id,
	generatedId,
	label,
	disabled,
	input = {},
	meta: { active, error, touched } = {},
	type = 'text',
	suffix,
	className,
	inputRef,
	...rest
}) => (
	<Box
		className={cx('form-group', 'form-group--text', className, {
			'is-focused': active,
			'has-value': !isEmpty(input.value),
			'has-error': error && touched,
			'has-suffix': suffix,
			'd-none': type === 'hidden',
			disabled,
		})}
	>
		<label className="control-label" htmlFor={id || generatedId}>
			{label}
		</label>
		<input
			className="form-control"
			id={id || generatedId}
			type={type}
			disabled={disabled}
			ref={inputRef}
			{...rest}
			{...input}
		/>
		{suffix && (
			<div className="input-group-append">
				<span className="input-group-text">{suffix}</span>
			</div>
		)}
		{error && touched && (
			<div className="invalid-feedback">
				<FormattedMessage {...error.message} values={error.messageValues} />
			</div>
		)}
	</Box>
);

InputField.propTypes = {
	className: string,
	disabled: bool,
	generatedId: string,
	id: string,
	input: any,
	inputRef: object,
	label: oneOfType([string, node, object]),
	meta: any,
	name: string.isRequired,
	suffix: oneOfType([string, element]),
	type: string,
};

export default o(withGeneratedId, withFormField)(InputField);
