import { composeMiddleware, makeActionTypes, makeConstantActionCreator } from '@redux-tools/react';
import { makeMiddleware, typeEq } from '@uamk/utils';
import { pick } from 'ramda';

import { StorageKeys } from './constants';
import { Storage } from './storage';

export const ActionTypes = makeActionTypes('@persistor', ['PERSIST', 'PURGE']);

export const persist = makeConstantActionCreator(ActionTypes.PERSIST);
export const purge = makeConstantActionCreator(ActionTypes.PURGE);

export const makePersistorMiddleware = ({ whitelistKeys = [] }) => {
	const persistMiddleware = makeMiddleware(
		typeEq(ActionTypes.PERSIST),
		({ getState }) => async () => {
			const relevantState = pick(whitelistKeys, getState());
			try {
				await Storage.setItem(StorageKeys.UAMK_STORE_KEY, JSON.stringify(relevantState));
			} catch (error) {
				console.error(
					'Cannot persist redux state. User will be logged off on page refresh.',
					error.message
				);
			}
		}
	);

	const purgeMiddleware = makeMiddleware(typeEq(ActionTypes.PURGE), () => async () => {
		await Storage.removeItem(StorageKeys.UAMK_STORE_KEY);
	});

	return composeMiddleware(persistMiddleware, purgeMiddleware);
};
