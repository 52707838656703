import React, { Fragment, useEffect, useState } from 'react';
import { func, number } from 'prop-types';
import { noop } from 'ramda-extension';

import { formatTime } from './formatTime';

const CountDown = ({ milliseconds, afterCountDownAction = noop }) => {
	const [count, setCount] = useState(undefined);
	const [timeLeft, setTimeLeft] = useState(milliseconds > 0 ? milliseconds : 0);
	const [intervalId, setIntervalId] = useState(undefined);

	useEffect(() => {
		if (count) {
			if (timeLeft <= 0) {
				if (intervalId) {
					clearInterval(intervalId);
				}
				afterCountDownAction();
				setTimeLeft(0);
			} else {
				setTimeLeft(timeLeft - 1000);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [count]);

	useEffect(() => {
		if (milliseconds <= 0) {
			return;
		}
		const intervalID = setInterval(() => setCount(new Date().getTime()), 1000);
		setIntervalId(intervalID);

		return () => clearInterval(intervalID);
	}, [milliseconds]);

	return <Fragment>{formatTime(timeLeft)}</Fragment>;
};

CountDown.propTypes = { afterCountDownAction: func, milliseconds: number };

export default CountDown;
