import React, { forwardRef } from 'react';
import { generateId } from '@uamk/utils';

import getDisplayName from './getDisplayName';

const withGeneratedId = (NextComponent) => {
	const WithGeneratedId = forwardRef((props, ref) => {
		const generatedId = generateId();

		return <NextComponent generatedId={generatedId} ref={ref} {...props} />;
	});

	WithGeneratedId.displayName = `withGeneratedId(${getDisplayName(NextComponent)})`;

	return WithGeneratedId;
};

export default withGeneratedId;
