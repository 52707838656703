import React, { lazy } from 'react';
import { Routes } from '@uamk/navigation';
import { FormattedMessage } from 'react-intl';

import m from '../messages';

const Authors = lazy(() => import(/* webpackPrefetch: true */ '@uamk/module-authors'));
const Incidents = lazy(() => import(/* webpackPrefetch: true */ '@uamk/module-incidents'));
const Users = lazy(() => import(/* webpackPrefetch: true */ '@uamk/module-users'));
const Technics = lazy(() => import(/* webpackPrefetch: true */ '@uamk/module-technics'));
const Economics = lazy(() => import(/* webpackPrefetch: true */ '@uamk/module-economics'));
const Pricelists = lazy(() => import(/* webpackPrefetch: true */ '@uamk/module-pricelists'));
const Notifications = lazy(() => import(/* webpackPrefetch: true */ '@uamk/module-notifications'));

const Login = lazy(() => import('@uamk/module-auth/src/containers/Login'));
const PwdNew = lazy(() => import('@uamk/module-auth/src/containers/PwdNew'));
const PwdReset = lazy(() => import('@uamk/module-auth/src/containers/PwdReset'));

// Items visible in primary menu (must have all keys - icon, label, path, component)
export const primaryMenuNavigationItems = {
	incidents: {
		icon: 'action',
		label: <FormattedMessage {...m.navIncidents} />,
		path: Routes.incidents,
		component: Incidents,
	},
	users: {
		icon: 'lide',
		label: <FormattedMessage {...m.navUsers} />,
		path: Routes.users,
		component: Users,
	},
	technics: {
		icon: 'car',
		label: <FormattedMessage {...m.navTechnics} />,
		path: Routes.technics,
		component: Technics,
	},
	economics: {
		icon: 'payments',
		label: <FormattedMessage {...m.navEconomics} />,
		path: Routes.economics,
		component: Economics,
	},
	pricelists: {
		icon: 'pricelist',
		label: <FormattedMessage {...m.navPricelists} />,
		path: Routes.pricelists,
		component: Pricelists,
	},
	authors: {
		icon: 'authority',
		label: <FormattedMessage {...m.navAuthors} />,
		path: Routes.authors,
		component: Authors,
	},
};

// Items visible in secondary menu (must have keys label, path, component)
export const secondaryNavigationItems = {
	notifications: {
		path: Routes.notifications,
		component: Notifications,
	},
	profile: {
		path: Routes.profile,
		component: PwdNew,
	},
};

// Public routes accessible without authentication (must have keys - path, component)
export const publicNavigationItems = {
	login: {
		path: Routes.login,
		component: Login,
	},
	reset: {
		path: Routes.reset,
		component: PwdReset,
	},
	password: {
		path: Routes.password,
		component: PwdNew,
	},
};

export const pagesWithStepper = [
	'/incidents/new',
	'/incidents/edit/',
	'/users/new',
	'/users/edit/',
	'/technics/new',
	'/technics/edit/',
];
