import React, { useState } from 'react';
import { func, node, string } from 'prop-types';
import { cx } from 'ramda-extension';
import { Box, Button, Icon } from 'lundium';

import OutsideClickBoundary from '../OutsideClickBoundary';

const ButtonGroup = ({ kind = 'primary', label, action, children, dropdownClassName }) => {
	const [isMenuOpen, toggleMenu] = useState(false);

	return (
		<OutsideClickBoundary onOutsideClick={() => toggleMenu(false)}>
			<Box className="dropdown d-inline-block">
				<Box className={cx('btn-group', { 'btn-group--open': isMenuOpen })}>
					<Button kind={kind} onClick={action}>
						{label}
					</Button>
					<Button
						kind="primary"
						className="dropdown__toggle"
						icon={<Icon type="arrow_down" color="inherit" />}
						onClick={() => toggleMenu(!isMenuOpen)}
					/>
				</Box>
				<Box
					className={cx('dropdown-menu dropdown-menu-right', dropdownClassName, {
						show: isMenuOpen,
					})}
				>
					{children}
				</Box>
			</Box>
		</OutsideClickBoundary>
	);
};

ButtonGroup.propTypes = {
	action: func,
	children: node,
	dropdownClassName: string,
	kind: string,
	label: node,
};

export default ButtonGroup;
