import React, { Fragment, useCallback } from 'react';
import { take, without } from 'ramda';
import { any, arrayOf, bool, number, string } from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Box, Grid, Icon, Link, Text } from 'lundium';
import { FormattedMessage } from 'react-intl';
import { readFileAsText } from '@uamk/utils';
import { touch } from 'redux-form';

import m from '../../messages';
import { withFormField } from '../../utils';

const deactiveZone = { onClick: (event) => event.stopPropagation() };

const DropzoneField = ({
	input: { name, onChange, value } = {},
	meta: { error, touched, form } = {},
	acceptedFileTypes = [],
	maxFiles,
	maxSize,
	disabled,
}) => {
	touch(form, name);
	const onDrop = useCallback(
		async (acceptedFiles) => {
			if (acceptedFiles.length > maxFiles) {
				return;
			}
			const files = await Promise.all(acceptedFiles.map(readFileAsText));
			onChange(take(maxFiles, [...files, ...value]));
		},
		[onChange, value, maxFiles]
	);

	const acceptFiles = value.length < maxFiles;

	const { getRootProps, getInputProps } = useDropzone({
		accept: acceptedFileTypes,
		onDrop,
		maxFiles,
		disabled,
		noDrag: !acceptFiles,
	});

	const removeFile = (file, value) => () => {
		const newFiles = without([file], value);
		onChange([...newFiles]);
	};

	return (
		<Fragment>
			<Box className="upload-box" {...getRootProps(acceptFiles ? {} : deactiveZone)}>
				<Box className="upload-box__content">
					<input name={name} {...getInputProps()} />
					<Text mb={2}>
						<FormattedMessage {...m.dropzoneTitle} />
						{acceptFiles ? (
							<Link>
								<FormattedMessage {...m.dropzoneTitleLink} />
							</Link>
						) : (
							<Text as="strong">
								<FormattedMessage {...m.dropzoneTitleLink} />
							</Text>
						)}
					</Text>
					<Text className="upload-box__content-subtitle" mb={0}>
						<FormattedMessage {...m.dropzoneSubtitle} values={{ maxFiles }} />
						<br />
						<FormattedMessage {...m.dropzoneSupportedFileTypes} />
						{acceptedFileTypes.map((item) => ` ${item}`)}
						<br />
						{maxSize && <FormattedMessage {...m.dropzoneMaxSizeDescription} values={{ maxSize }} />}
					</Text>
				</Box>
				{touched && error && (
					<Box className="invalid-feedback">{<FormattedMessage {...error.message} />}</Box>
				)}
			</Box>
			{value && Array.isArray(value) && (
				<Grid row>
					<Grid col={8}>
						{value.map((file, i, thisOne) => (
							<Box key={i} className="upload-box__item">
								<Box
									className="upload-box__item-label"
									onClick={() => {
										const fileObjectURL = URL.createObjectURL(file);
										window.open(fileObjectURL);
									}}
								>
									{file.name}
								</Box>
								<Icon type="close" color="primary" onClick={removeFile(file, thisOne)} />
							</Box>
						))}
					</Grid>
				</Grid>
			)}
		</Fragment>
	);
};

DropzoneField.propTypes = {
	acceptedFileTypes: arrayOf(string),
	disabled: bool,
	input: any,
	maxFiles: number,
	maxSize: number,
	meta: any,
};

export default withFormField(DropzoneField);
