import { number, shape } from 'prop-types';

export const apiPaginationShape = shape({
	pageNumber: number,
	pageCount: number,
	totalCount: number,
	pageSize: number,
});

export const defaultPageSizeOptions = [10, 25, 50, 100, 200];

export const defaultMaxSize = 2097152;
export const defaultMaxFiles = 100;
export const defaultImgTypes = ['.png', '.jpg', '.jpeg'];

export const compressorOptions = {
	quality: 0.8, // Recommended output quality
	minWidth: 0, // FIX: small images compress fix
	minHeight: 0, // FIX: small images compress fix
	maxWidth: 4681, // Represents A0 format
	maxHeight: 3311, // Represents A0 format
	convertSize: 0, // FIX: small images compress fix
};
