import React from 'react';
import { o } from 'ramda';
import { cx } from 'ramda-extension';
import { any, bool, node, object, oneOfType, string } from 'prop-types';
import { Box } from 'lundium';

import { withFormField, withGeneratedId } from '../../utils';

export const SwitchField = ({
	id,
	generatedId,
	label,
	inactiveLabel = '',
	disabled,
	input = {},
	meta: { active } = {},
	type = 'checkbox',
	className,
	formGroupProps = {},
	inputRef,
	...rest
}) => {
	const fieldId = id || generatedId;

	return (
		<Box
			className={cx('custom-control custom-switch', className, {
				'is-focused': active && !disabled,
				'has-value': input.value,
				disabled,
			})}
			{...formGroupProps}
		>
			<input
				className="custom-control-input"
				id={fieldId}
				type={type}
				ref={inputRef}
				checked={input.value === true}
				disabled={disabled}
				{...rest}
				{...input}
			/>
			<label className="custom-control-label" htmlFor={fieldId}>
				{input.value === false && inactiveLabel ? inactiveLabel : label}
			</label>
		</Box>
	);
};

SwitchField.propTypes = {
	className: string,
	disabled: bool,
	formGroupProps: any,
	generatedId: string,
	id: string,
	inactiveLabel: oneOfType([string, node, object]),
	input: any,
	inputRef: object,
	label: oneOfType([string, node, object]),
	meta: any,
	type: string,
};

export default o(withGeneratedId, withFormField)(SwitchField);
