import React, { Fragment, useEffect } from 'react';
import { applySpec, concat } from 'ramda';
import { connect } from 'react-redux';
import invariant from 'invariant';
import { array, func, object, string } from 'prop-types';
import { Box } from 'lundium';

import {
	cleanModalContainer,
	closeModal,
	closeModalAll,
	getCurrentModal,
	isModalActive,
	openModal,
} from './duck';
import { modalActionPropTypes } from './constants';
import { eqPropsArray } from './utils';

/**
 * Modal Manager. Stores all possible modals and manages them via redux store.
 * Should be only one instance per page.
 * @param children - An object with available modals
 * @param wrapper - A global wrapper for all modals (may be overridden)
 * @param openModal - Open new modal by ID
 * @param closeModal - Close current modal
 * @param closeModalAll - Close all modals
 */
const ModalManager = ({
	id: managerId,
	children,
	componentWrapper: WrapperGlobal = ({ children }) => children,
	currentModal,
	isModalActive,
	openModal,
	closeModal,
	closeModalAll,
	cleanModalContainer,
}) => {
	useEffect(() => () => cleanModalContainer({ managerId }), [cleanModalContainer, managerId]);

	// Prevent scrolling
	useEffect(() => {
		if (isModalActive) {
			document.body.classList.add('modal-open');
		} else {
			document.body.classList.remove('modal-open');
		}

		// Clean added class
		return () => document.body.classList.remove('modal-open');
	}, [isModalActive]);

	return (
		<Fragment>
			{children.map(
				({
					id: modalId,
					componentWrapper: WrapperModal,
					componentContent: Content,
					initialState = {},
					customActions = {},
				}) => {
					const isActive = eqPropsArray(
						['managerId', 'modalId'],
						{ managerId, modalId },
						currentModal
					);
					const Wrapper = WrapperModal ?? WrapperGlobal;
					const actions = {
						openModal,
						closeModal,
						closeModalAll,
					};

					invariant(
						Content,
						`ModalManager "${managerId}" with Modal "${modalId}" does not have componentContent.`
					);

					return (
						<Box key={concat(managerId, modalId)}>
							{isActive && (
								<Wrapper isActive={isActive} actions={actions}>
									<Content
										actions={{ ...actions, ...customActions }}
										data={currentModal?.data || initialState}
									/>
								</Wrapper>
							)}
						</Box>
					);
				}
			)}
		</Fragment>
	);
};

ModalManager.propTypes = {
	children: array.isRequired,
	componentWrapper: func,
	containers: object,
	id: string.isRequired,
	...modalActionPropTypes,
};

export default connect(
	applySpec({
		currentModal: getCurrentModal,
		isModalActive,
	}),
	{
		openModal,
		closeModal,
		closeModalAll,
		cleanModalContainer,
	}
)(ModalManager);
