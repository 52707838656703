/** Utility functions for MultiSelect component only */
import { allPass, has, map } from 'ramda';
import { isObject } from 'ramda-extension';
import { useIntl } from 'react-intl';

export const isSelected = ({ selected }) => selected;

export const setDefaultSelections = (defaults = []) =>
	map(({ value, ...rest }) => ({
		...rest,
		value,
		selected: defaults.includes(value),
	}));

const isObjectMessage = allPass([isObject, has('id')]);

export const getSelected = ({ defaultValue = '', allSelected = '', manySelected = '' }) => (
	items = []
) => {
	const intl = useIntl();
	const selected = items.filter(isSelected);
	const length = selected.length;
	if (items.length === length && items.length > 0) {
		return allSelected;
	}
	if (length <= 0) {
		return defaultValue;
	}
	if (length === 1) {
		const label = selected[0]?.label;

		return isObjectMessage(label?.props) ? intl.formatMessage(label.props) : selected[0]?.label;
	}
	if (length >= 1) {
		return `${length} ${manySelected}`;
	}
};
