import React, { useCallback, useState } from 'react';
import { Icon } from 'lundium';
import { compose } from 'ramda';
import { isNilOrEmptyString } from 'ramda-extension';

import { withFormField, withGeneratedId } from '../../utils';
import { InputField } from '../InputField/InputField';

export const PasswordField = (props) => {
	const [isPassword, setIsPassword] = useState(true);
	const { input } = props;

	const handleShowPassword = useCallback(() => {
		setIsPassword(false);
	}, []);

	const handleHidePassword = useCallback(() => {
		setIsPassword(true);
	}, []);

	const suffix = isNilOrEmptyString(input?.value) ? null : isPassword ? (
		<div className="input-group-append">
			<span className="input-group-text">
				<Icon type="eye" color="primary" onClick={handleShowPassword} />
			</span>
		</div>
	) : (
		<div className="input-group-append">
			<span className="input-group-text">
				<Icon type="eye_off" color="primary" onClick={handleHidePassword} />
			</span>
		</div>
	);

	return <InputField type={isPassword ? 'password' : 'text'} {...props} suffix={suffix} />;
};

PasswordField.propTypes = InputField.propTypes;

export default compose(withGeneratedId, withFormField)(PasswordField);
