import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Grid } from 'lundium';
import { node } from 'prop-types';

import Footer from '../Footer';
import Logo from '../../assets/images/layout/logo.svg';
import m from '../../messages';

const PublicLayout = ({ children }) => {
	const year = new Date().getFullYear();

	return (
		<Box className="layout">
			<Box as="header" className="header-public">
				<Grid container>
					<Logo className="logo" />
				</Grid>
			</Box>
			<Box className="content" ml={0}>
				{children}
			</Box>
			<Footer>
				<FormattedMessage {...m.copyright} values={{ year }} />
			</Footer>
		</Box>
	);
};

PublicLayout.propTypes = {
	children: node,
	logo: node,
};

export default PublicLayout;
