import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isApiumError, isApiumSuccess, isFetching, request } from 'apium';
import {
	composeMiddleware,
	makeActionTypes,
	makeEmptyActionCreator,
	makePayloadActionCreator,
	makePayloadMetaActionCreator,
	makeReducer,
} from '@redux-tools/react';
import { addNotification, type } from '@uamk/notifications';
import { appendQueryParams, makeMiddleware, typeEq } from '@uamk/utils';
import { anyPass, filter, o, prop } from 'ramda';
import { isNilOrEmpty, isNotEmpty, isNotNil } from 'ramda-extension';

import m from './messages';
import { notificationsApi as url } from './constants';

const ActionTypes = makeActionTypes('@userNotifications', [
	'FETCH',
	'FETCH_UNREADS',
	'MARK_AS_READ',
	'DELETE',
	'DELETE_MANY',
	'DELETE_ALL',
	'STORE',
	'STORE_IS_PENDING',
]);

export const fetchNotifications = makePayloadMetaActionCreator(ActionTypes.FETCH);
export const fetchUnreadCount = makeEmptyActionCreator(ActionTypes.FETCH_UNREADS);
export const markNotificationRead = makePayloadActionCreator(ActionTypes.MARK_AS_READ);
export const deleteNotification = makePayloadMetaActionCreator(ActionTypes.DELETE);
export const deleteNotificationsMany = makePayloadActionCreator(ActionTypes.DELETE_MANY);
export const deleteNotificationsAll = makeEmptyActionCreator(ActionTypes.DELETE_ALL);
const updateNotifications = makePayloadActionCreator(ActionTypes.STORE);
// NOTE: apium isFetching does not work in mobile app
const storeIsPending = makePayloadActionCreator(ActionTypes.STORE_IS_PENDING);

export const isNotificationsPending = isFetching(ActionTypes.FETCH);

const defaultPaging = { page: 0, size: 10 };

export const getUnreadCount = ({ userNotifications: { countUnreads } = {} }) => countUnreads;
export const getHasNewNotifications = ({ userNotifications: { countUnreads } = {} }) =>
	countUnreads > 0 ?? false;
export const getNotificationItems = ({ userNotifications: { notifications } = {} }) =>
	notifications?.items || [];
export const getPaginationData = ({
	userNotifications: { notifications: { pageNumber, pageCount, totalCount, pageSize } = {} } = {},
}) => ({ pageNumber, pageCount, totalCount, pageSize });
export const getTotalCount = o(prop('totalCount'), getPaginationData);
export const getNotificationsStatus = (state) =>
	state.userNotifications?.notifications?.status ?? false;
export const getIsNotificationsPending = (state) => state.userNotifications?.isPending ?? false;

const fetchNotificationsMiddleware = makeMiddleware(
	typeEq(ActionTypes.FETCH),
	({ dispatch }) => (action) => {
		const { payload = {} } = action;
		const params = { ...defaultPaging, ...filter(isNotNil, payload) };

		dispatch(storeIsPending(true));
		dispatch(request({ url: appendQueryParams(url, params) }, { origin: action }));
	}
);

const fetchNotificationsSuccessMiddleware = makeMiddleware(
	isApiumSuccess(ActionTypes.FETCH),
	({ dispatch }) => ({ payload: { items = [], ...rest }, meta = {} }) => {
		dispatch(updateNotifications({ notifications: { ...rest, items, status: true } }));
		dispatch(storeIsPending(false));

		if (!isNilOrEmpty(meta.origin.meta)) {
			meta.origin.meta.endRefresh();
		}
	}
);

const fetchNotificationsErrorMiddleware = makeMiddleware(
	isApiumError(ActionTypes.FETCH),
	({ dispatch }) => ({ meta = {} }) => {
		dispatch(updateNotifications({ notifications: { items: [], status: false } }));
		dispatch(storeIsPending(false));
		dispatch(
			addNotification({
				message: <FormattedMessage {...m.fetchNotificationError} />,
				type: type.ERROR,
			})
		);

		if (!isNilOrEmpty(meta.origin.meta)) {
			meta.origin.meta.endRefresh();
		}
	}
);

const fetchUnreadCountMiddleware = makeMiddleware(
	typeEq(ActionTypes.FETCH_UNREADS),
	({ dispatch }) => (action) => dispatch(request({ url: `${url}/unreadCount` }, { origin: action }))
);

const fetchUnreadCountSuccessMiddleware = makeMiddleware(
	isApiumSuccess(ActionTypes.FETCH_UNREADS),
	({ dispatch }) => ({ payload: { totalCount = 0 } }) =>
		dispatch(updateNotifications({ countUnreads: totalCount }))
);

const markNotificationUnreadMiddleware = makeMiddleware(
	typeEq(ActionTypes.MARK_AS_READ),
	({ dispatch }) => (action) => {
		const { id } = action?.payload || {};

		dispatch(request({ method: 'PUT', url: `${url}/${id}/markAsRead` }, { origin: action }));
	}
);

const markNotificationUnreadSuccessMiddleware = makeMiddleware(
	isApiumSuccess(ActionTypes.MARK_AS_READ),
	({ dispatch }) => () => {
		dispatch(fetchNotifications({}, {}));
		dispatch(fetchUnreadCount());
	}
);

const deleteNotificationMiddleware = makeMiddleware(
	typeEq(ActionTypes.DELETE),
	({ dispatch }) => (action) => {
		const { id } = action?.payload || {};
		dispatch(request({ method: 'DELETE', url: `${url}/${id}` }, { origin: action }));
	}
);

const deleteNotificationSuccessMiddleware = makeMiddleware(
	isApiumSuccess(ActionTypes.DELETE),
	({ dispatch }) => ({ payload, meta }) => {
		if (meta.res) {
			const id = meta.origin.payload.id;

			meta.res(id);
		} else {
			dispatch(
				addNotification({
					message: payload?.message || <FormattedMessage {...m.notificationDeleted} />,
					type: type.SUCCESS,
				})
			);

			dispatch(fetchNotifications({}, {}));
			dispatch(fetchUnreadCount());
		}
	}
);

const deleteNotificationsManyMiddleware = makeMiddleware(
	typeEq(ActionTypes.DELETE_MANY),
	({ dispatch }) => (action) => {
		const { selectedItems } = action?.payload || {};

		if (isNotEmpty(selectedItems)) {
			const notificationsArray = selectedItems.map(
				(id) => new Promise((res, rej) => dispatch(deleteNotification({ id }, { res, rej })))
			);

			return Promise.all(notificationsArray).then(() => {
				dispatch(
					addNotification({
						message: <FormattedMessage {...m.notificationsDeleted} />,
						type: type.SUCCESS,
					})
				);

				dispatch(fetchNotifications({}, {}));
				dispatch(fetchUnreadCount());
			});
		}
	}
);

const deleteNotificationsAllMiddleware = makeMiddleware(
	typeEq(ActionTypes.DELETE_ALL),
	({ dispatch }) => (action) => {
		dispatch(request({ method: 'DELETE', url: `${url}` }, { origin: action }));
	}
);

const deleteNotificationsAllSuccessMiddleware = makeMiddleware(
	isApiumSuccess(ActionTypes.DELETE_ALL),
	({ dispatch }) => () => {
		dispatch(
			addNotification({
				message: <FormattedMessage {...m.allNotificationsDeleted} />,
				type: type.SUCCESS,
			})
		);

		dispatch(fetchNotifications({}, {}));
		dispatch(fetchUnreadCount());
	}
);

const fetchNotificationErrorsMiddleware = makeMiddleware(
	anyPass([
		isApiumError(ActionTypes.FETCH_UNREADS),
		isApiumError(ActionTypes.MARK_AS_READ),
		isApiumError(ActionTypes.DELETE),
		isApiumError(ActionTypes.DELETE_ALL),
	]),
	({ dispatch }) => ({ payload }) => {
		dispatch(
			addNotification({
				message: payload?.detail || <FormattedMessage {...m.fetchNotificationError} />,
				type: type.ERROR,
			})
		);
	}
);

const initialState = {
	countUnreads: 0,
	notifications: {},
};

export const notificationsMiddleware = composeMiddleware(
	fetchUnreadCountMiddleware,
	fetchNotificationsMiddleware,
	fetchUnreadCountSuccessMiddleware,
	fetchNotificationsSuccessMiddleware,
	fetchNotificationsErrorMiddleware,
	markNotificationUnreadMiddleware,
	markNotificationUnreadSuccessMiddleware,
	deleteNotificationMiddleware,
	deleteNotificationSuccessMiddleware,
	deleteNotificationsManyMiddleware,
	deleteNotificationsAllMiddleware,
	deleteNotificationsAllSuccessMiddleware,
	fetchNotificationErrorsMiddleware
);

export default makeReducer(
	[
		[ActionTypes.STORE, (state, { payload }) => ({ ...state, ...payload })],
		[ActionTypes.STORE_IS_PENDING, (state, { payload }) => ({ ...state, isPending: payload })],
	],
	initialState
);
