import React from 'react';
import { bool, shape } from 'prop-types';
import { Box } from 'lundium';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'ramda';
import { cx } from 'ramda-extension';

import m from '../../messages';

const TableCellActive = ({
	row: {
		values: { active },
	},
}) =>
	isNil(active) ? null : (
		<Box
			as="span"
			className={cx('table__badge', {
				'table__badge--new': active,
				'table__badge--done': !active,
			})}
		>
			<FormattedMessage {...(active ? m.cellActive : m.cellInactive)} />
		</Box>
	);

TableCellActive.propTypes = {
	row: shape({
		values: shape({
			active: bool,
		}),
	}),
};

export default TableCellActive;
