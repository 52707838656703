export const notificationTypes = {
	NEW: 'NEW',
	TIMED_OUT: 'TIMED_OUT',
	REJECTED: 'REJECTED',
	ACCEPTED: 'ACCEPTED',
	DRIVER_ACCEPTED: 'DRIVER_ACCEPTED',
	DRIVER_REJECTED: 'DRIVER_REJECTED',
	TECHNICS_IN_USE: 'TECHNICS_IN_USE',
	ARRIVED: 'ARRIVED',
	ENDED: 'ENDED',
	APPROVED: 'APPROVED',
	CANCELED_BY_DRIVER: 'CANCELED_BY_DRIVER',
	DATA_CHANGED: 'DATA_CHANGED',
	STATE_CHANGED: 'STATE_CHANGED',
};
