import { StorageKeys } from './constants';

export const Storage = localStorage;

export const getInitialState = () => {
	try {
		const persistedState = JSON.parse(Storage?.getItem(StorageKeys.UAMK_STORE_KEY));

		return persistedState || {};
	} catch (error) {
		console.error('Error getting state from localstorage', error.message);

		return {};
	}
};
