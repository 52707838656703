import React, { Children, Fragment, createElement, useEffect, useState } from 'react';
import { bool, func, node, oneOf, string } from 'prop-types';
import { dissoc, is, length } from 'ramda';
import { cx, noop } from 'ramda-extension';
import { Box, Button, Grid } from 'lundium';
import { FormattedMessage, useIntl } from 'react-intl';

import SectionHeader from '../SectionHeader';
import m from '../../messages';
import useIsGtThenViewport from '../../hooks';

const omitLabel = dissoc('stepperLabel');

const FormWizard = ({
	children,
	title,
	firstStepBtnLabel,
	lastStepBtnLabel,
	firstStepBtnAction = noop,
	lastStepBtnAction = noop,
	isInvalid,
	formId,
}) => {
	const [activeStep, setActiveStep] = useState(0);
	const totalSteps = length(children);
	const isItemDone = (index) => activeStep > index;
	const isMdViewport = useIsGtThenViewport(720);
	const { formatMessage } = useIntl();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [activeStep]);

	const stepperHeader = Children.map(children, ({ props: { stepperLabel } }, index) => (
		<Box
			className={cx('stepper__item', {
				'stepper__item--active': activeStep === index,
				'stepper__item--done': isItemDone(index),
			})}
			onClick={() => (isItemDone(index) ? setActiveStep(index) : noop)}
		>
			<Box className="stepper__item-badge">{index + 1}</Box>
			<Box className="stepper__item-label">{stepperLabel}</Box>
		</Box>
	));

	const stepperOptions = Children.map(children, ({ props: { stepperLabel: label } }, index) => ({
		label: is(String, label) ? label : formatMessage(label?.props),
		value: index,
	}));

	return (
		<Fragment>
			<SectionHeader
				title={title}
				isBottomContent
				content={
					isMdViewport ? (
						<Box className="stepper">{stepperHeader}</Box>
					) : (
						<Box className="stepper">
							<Box className="stepper__badge">
								{activeStep + 1}/{totalSteps}
							</Box>
							<Box className="form-group form-group--select has-value w-100 mb-0">
								<label htmlFor={`form-wizard-${formId}`}>
									<FormattedMessage {...m.step} />
								</label>
								<select
									className="form-control"
									id={`form-wizard-${formId}`}
									onChange={(e) => setActiveStep(Number(e.target.value))}
									value={activeStep}
								>
									{stepperOptions.map(({ label, value }, index) => (
										<option
											key={`${value}-${label}`}
											value={value}
											disabled={activeStep < index && isInvalid}
										>
											{label}
										</option>
									))}
								</select>
							</Box>
						</Box>
					)
				}
			/>
			<Box className="content content--with-stepper">
				{Children.map(children, ({ props, type }, index) => (
					<Fragment>
						{activeStep === index &&
							createElement(type, {
								...omitLabel(props),
							})}
					</Fragment>
				))}
			</Box>
			<Box className="stepper__footer">
				<Grid container py={{ md: 2 }} px={{ xl: 4 }}>
					<Grid row>
						<Grid col={{ xs: 12, md: 10 }} className="text-center">
							<Button
								mr={3}
								className="text-uppercase"
								onClick={() =>
									activeStep === 0 ? firstStepBtnAction() : setActiveStep(activeStep - 1)
								}
							>
								{activeStep === 0 ? firstStepBtnLabel : <FormattedMessage {...m.back} />}
							</Button>
							<Button
								kind="primary"
								className="text-uppercase"
								disabled={isInvalid}
								onClick={() =>
									activeStep + 1 === totalSteps
										? lastStepBtnAction()
										: setActiveStep(activeStep + 1)
								}
							>
								{activeStep + 1 === totalSteps ? (
									lastStepBtnLabel
								) : (
									<FormattedMessage {...m.next} />
								)}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Fragment>
	);
};

FormWizard.propTypes = {
	children: node,
	firstStepBtnAction: func,
	firstStepBtnLabel: node,
	formId: string,
	isInvalid: bool,
	lastStepBtnAction: func,
	lastStepBtnLabel: node,
	title: oneOf([string, node]),
};

export default FormWizard;
