import React from 'react';
import { Box } from 'lundium';
import { bool } from 'prop-types';
import { cx } from 'ramda-extension';

const Backdrop = ({ isOpen }) => (
	<Box className={cx('backdrop--clickable', { 'backdrop--open': isOpen })} />
);

Backdrop.propTypes = {
	isOpen: bool,
};

export default Backdrop;
