import React from 'react';
import { format, isToday } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { getLocaleDateTime } from '@uamk/utils';

import m from '../messages';

/**
 * Given an ISO string date '2021-06-09T14:00:00Z' returns 'Today 8:00' string if date is today.
 * if it is not today's date just return formatted date and time as '9. 6. 2021 8:00'
 */
export const formatHumanDateTime = (dateStr) => {
	const date = new Date(dateStr);

	return isToday(date) ? (
		<FormattedMessage {...m.todaysDate} values={{ time: format(date, 'H:mm') }} />
	) : (
		getLocaleDateTime(date)
	);
};
