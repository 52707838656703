import React, { useEffect, useMemo } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateLayout } from '@uamk/ui-components';
import { getObjectValuesKeys } from '@uamk/utils';
import {
	getUserName,
	getUserPartnerName,
	hasAdminAuthority,
	isAuthorized,
	revokeToken,
} from '@uamk/domain-auth';
import { fetchUnreadCount, getUnreadCount } from '@uamk/domain-notifications';
import { Routes } from '@uamk/navigation';
import { shouldForceRefetch } from '@uamk/websockets';
import { connect } from 'react-redux';
import { applySpec, omit } from 'ramda';

import {
	pagesWithStepper,
	primaryMenuNavigationItems,
	secondaryNavigationItems,
} from '../constants/navigation';
import Notifications from './Notifications';

const Authenticated = ({
	hasAdminAuthority,
	isAuthorized,
	unreadCount,
	fetchUnreadCount,
	userPartnerName,
	username,
	revokeToken,
	location: { pathname },
	shouldForceRefetch,
}) => {
	const primaryMenuItems = useMemo(
		() =>
			hasAdminAuthority
				? primaryMenuNavigationItems
				: omit(['pricelists'], primaryMenuNavigationItems),
		[hasAdminAuthority]
	);

	const privateRoutes = useMemo(
		() =>
			getObjectValuesKeys(['path', 'component'])({
				...primaryMenuItems,
				...secondaryNavigationItems,
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const menuNavItems = useMemo(
		() => getObjectValuesKeys(['icon', 'label', 'path'])(primaryMenuItems),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	const hasStepper = pagesWithStepper.some((sub) => pathname.includes(sub));

	useEffect(() => {
		if (shouldForceRefetch && isAuthorized) {
			fetchUnreadCount();
		}
	}, [fetchUnreadCount, shouldForceRefetch, isAuthorized]);

	useEffect(() => {
		if (isAuthorized) {
			fetchUnreadCount();
		}
	}, [fetchUnreadCount, isAuthorized]);

	return isAuthorized ? (
		<PrivateLayout
			items={menuNavItems}
			logout={revokeToken}
			hasStepper={hasStepper}
			name={`${username} (${userPartnerName})`}
			profilePath={Routes.profile}
			notifications={Notifications}
			notificationProps={{ unreadCount, notificationPath: Routes.notifications }}
		>
			<Switch>
				<Route exact path={Routes.root} component={() => <Redirect to={Routes.incidents} />} />
				{privateRoutes.map((route) => (
					<Route key={route.path} {...route} />
				))}
			</Switch>
		</PrivateLayout>
	) : (
		<Redirect to={Routes.login} />
	);
};

Authenticated.propTypes = {
	fetchUnreadCount: func,
	hasAdminAuthority: bool,
	isAuthorized: bool,
	location: shape({
		pathname: string,
	}),
	revokeToken: func,
	shouldForceRefetch: bool,
	unreadCount: number,
	userPartnerName: string,
	username: string,
};

export default connect(
	applySpec({
		isAuthorized,
		hasAdminAuthority,
		userPartnerName: getUserPartnerName,
		username: getUserName,
		unreadCount: getUnreadCount,
		shouldForceRefetch,
	}),
	{
		revokeToken,
		fetchUnreadCount,
	}
)(Authenticated);
