import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { forEach, without } from 'ramda';
import { any, arrayOf, bool, number, string } from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Box, Grid, Icon, Link, Text } from 'lundium';
import { FormattedMessage } from 'react-intl';
import { usePrevious } from '@uamk/utils';
import Compressor from 'compressorjs';
import { useDispatch } from 'react-redux';
import { addNotification, type } from '@uamk/notifications';

import m from '../../messages';
import { withFormField } from '../../utils';
import {
	compressorOptions,
	defaultImgTypes,
	defaultMaxFiles,
	defaultMaxSize,
} from '../../constants';

const DropzoneFieldImg = ({
	input: { name, onChange, value } = {},
	meta: { error } = {},
	acceptedFileTypes = defaultImgTypes,
	disabled,
	maxFileSize = defaultMaxSize,
	maxFiles = defaultMaxFiles,
}) => {
	const [accFiles, setAcceptedFile] = useState([]);
	const prevFiles = usePrevious(accFiles);
	const dispatch = useDispatch();

	const onDrop = useCallback(
		(acceptedFiles) =>
			forEach(
				(file) =>
					new Compressor(file, {
						...compressorOptions,
						success: (res) => {
							if (res.size < maxFileSize) {
								setAcceptedFile((prev) => [...prev, res]);
							} else {
								dispatch(
									addNotification({
										message: (
											<FormattedMessage {...m.dropzoneTooLarge} values={{ file: file.name }} />
										),
										type: type.ERROR,
									})
								);
							}
						},
					})
			)(acceptedFiles),
		[dispatch, maxFileSize]
	);

	useEffect(() => {
		if (accFiles !== prevFiles) {
			onChange(accFiles);
		}
	}, [accFiles, prevFiles, value, onChange]);

	const { getRootProps, getInputProps } = useDropzone({
		accept: acceptedFileTypes,
		onDrop,
		disabled,
	});

	const removeFile = (file) => () => {
		setAcceptedFile(without([file], accFiles));
	};

	return (
		<Fragment>
			<Box className="upload-box" {...getRootProps()}>
				<Box className="upload-box__content">
					<input name={name} {...getInputProps()} />
					<Text mb={2}>
						<FormattedMessage {...m.dropzoneTitle} />
						<Link>
							<FormattedMessage {...m.dropzoneTitleLink} />
						</Link>
					</Text>
					<Text className="upload-box__content-subtitle" mb={0}>
						<FormattedMessage {...m.dropzoneSubtitle} values={{ maxFiles }} />
						<br />
						<FormattedMessage {...m.dropzoneSupportedFileTypes} />
						{acceptedFileTypes.map((item) => ` ${item}`)}
					</Text>
				</Box>
			</Box>
			{error && (
				<Box className="invalid-feedback">
					<FormattedMessage {...error.message} values={error.messageValues} />
				</Box>
			)}
			{accFiles && Array.isArray(accFiles) && (
				<Grid row>
					<Grid col={12}>
						{accFiles.map((file, i) => (
							<Box key={i} className="upload-box__item">
								<Link
									className="upload-box__item-label"
									onClick={() => {
										const fileObjectURL = URL.createObjectURL(file);
										window.open(fileObjectURL);
									}}
								>
									{file.name}
								</Link>
								<Icon type="close" color="primary" onClick={removeFile(file, accFiles)} />
							</Box>
						))}
					</Grid>
				</Grid>
			)}
		</Fragment>
	);
};

DropzoneFieldImg.propTypes = {
	acceptedFileTypes: arrayOf(string),
	disabled: bool,
	input: any,
	maxFileSize: number,
	maxFiles: number,
	meta: any,
};

export default withFormField(DropzoneFieldImg);
