import React, { forwardRef, memo, useContext, useImperativeHandle, useMemo } from 'react';
import {
	arrayOf,
	bool,
	element,
	elementType,
	func,
	object,
	oneOf,
	shape,
	string,
} from 'prop-types';
import { useMountedLayoutEffect, useRowSelect, useTable } from 'react-table';
import { Box } from 'lundium';
import { filter, keys, length, o } from 'ramda';
import { cx, isNotEmpty, noop } from 'ramda-extension';

import TableCellCheckbox from '../TableCellCheckbox';
import TableHeaderCheckbox from '../TableHeaderCheckbox';
import { getSelectedRowIds } from '../../utils';
import { TableSelectionContext } from './TableSelectionContext';

const TableSelection = forwardRef(
	(
		{ columns, data, hiddenColumns = [], onItemClick = noop, className, rowProps = () => ({}) },
		ref
	) => {
		const { allDeselected, includeAndRemoveItems, selectedItems, setAllDeselected } = useContext(
			TableSelectionContext
		);

		const initialRowIds = getSelectedRowIds(data, selectedItems);
		const initialRowIdsKeys = useMemo(() => keys(initialRowIds), [initialRowIds]);
		const instance = useTable(
			{
				columns,
				data,
				initialState: {
					hiddenColumns,
					selectedRowIds: initialRowIds,
				},
				getRowId: (row) => row.id,
			},
			useRowSelect,
			(hooks) => {
				hooks.visibleColumns.push((columns) => [
					{
						id: 'selection',
						Header: TableHeaderCheckbox,
						Cell: TableCellCheckbox,
					},
					...columns,
				]);
			}
		);

		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			rows,
			prepareRow,
			state: { selectedRowIds },
			toggleAllRowsSelected,
		} = instance;

		useImperativeHandle(ref, () => instance);

		useMountedLayoutEffect(() => {
			const selectedRowIdsKeys = keys(selectedRowIds);
			const toBeIncluded = o(
				filter((id) => !initialRowIdsKeys.includes(id)),
				keys
			)(selectedRowIds);
			const toBeRemoved = initialRowIdsKeys.filter((id) => !selectedRowIdsKeys.includes(id));

			if ((isNotEmpty(toBeRemoved) || isNotEmpty(toBeIncluded)) && !allDeselected) {
				includeAndRemoveItems(toBeRemoved, toBeIncluded);
			}

			if (allDeselected) {
				toggleAllRowsSelected(false);
				setAllDeselected(false);
			}
		}, [includeAndRemoveItems, selectedRowIds]);

		return (
			<Box as="table" className={cx('table-full', className)} {...getTableProps()}>
				<Box as="thead">
					{headerGroups.map((headerGroup) => (
						<Box as="tr" {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<Box as="th" scope="col" key={column.id} {...column.getHeaderProps()}>
									{column.render('Header')}
								</Box>
							))}
						</Box>
					))}
				</Box>
				<Box as="tbody" {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row);
						const id = row?.values?.id;

						return (
							<Box as="tr" {...row.getRowProps(rowProps(row))}>
								{row.cells.map((cell, index) => (
									<Box as="td" key={`${id}-${index}`}>
										<Box
											{...cell.getCellProps()}
											onClick={() =>
												index !== 0 && index !== length(row.cells) - 1 ? onItemClick(id) : noop
											}
										>
											{cell.render('Cell')}
										</Box>
									</Box>
								))}
							</Box>
						);
					})}
				</Box>
			</Box>
		);
	}
);

TableSelection.propTypes = {
	allDeselected: bool,
	className: string,
	columns: arrayOf(
		shape({
			Header: oneOf([element, string]),
			accessor: string,
			Cell: elementType,
		})
	),
	data: arrayOf(object),
	formId: string,
	hiddenColumns: arrayOf(string),
	onItemClick: func,
	rowProps: func,
	setAllDeselected: func,
};

export default memo(TableSelection);
