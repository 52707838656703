import React, { useEffect } from 'react';
import { arrayOf, bool, func } from 'prop-types';
import { Box } from 'lundium';
import { connect } from 'react-redux';
import { applySpec, filter, o, take } from 'ramda';
import {
	fetchNotifications,
	getNotificationItems,
	isNotificationsPending,
	markNotificationRead,
	notificationShape,
} from '@uamk/domain-notifications';
import { FormattedMessage } from 'react-intl';
import { Routes } from '@uamk/navigation';
import { useHistory } from 'react-router-dom';
import { Loader, formatHumanDateTime } from '@uamk/ui-components';

import m from '../messages';

const isUnread = ({ readState }) => readState === false;
const getDisplayableNotifications = o(take(3), filter(isUnread));

const Notifications = ({
	notifications,
	fetchNotifications,
	isNotificationsPending,
	markNotificationRead,
}) => {
	const history = useHistory();

	useEffect(() => {
		fetchNotifications({}, {});
	}, [fetchNotifications]);

	const handleGoToIncident = (notificationId, incidentId) => () => {
		history.push({
			pathname: `${Routes.incidents}/${incidentId}`,
			state: { backPath: Routes.incidents },
		});
		markNotificationRead({ id: notificationId });
	};

	const handleGoToNotifications = () => history.push(Routes.notifications);

	return (
		<Box className="dropdown-menu dropdown-menu-right dropdown-menu--notify show">
			<Loader isActive={isNotificationsPending}>
				{getDisplayableNotifications(notifications).map(
					({ id, incidentId, createdAt, message }) => (
						<Box
							key={id}
							className="dropdown-item dropdown-item--notify"
							onClick={handleGoToIncident(id, incidentId)}
						>
							<Box className="dropdown-item__date">{formatHumanDateTime(createdAt)}</Box>
							<strong className="dropdown-item__text">{message}</strong>
						</Box>
					)
				)}
			</Loader>
			<Box className="dropdown-item dropdown-item--notify-link" onClick={handleGoToNotifications}>
				<Box className="dropdown-item__link">
					<FormattedMessage {...m.notificationViewAll} />
				</Box>
			</Box>
		</Box>
	);
};

Notifications.propTypes = {
	fetchNotifications: func,
	isNotificationsPending: bool,
	markNotificationRead: func,
	notifications: arrayOf(notificationShape),
};

export default connect(applySpec({ notifications: getNotificationItems, isNotificationsPending }), {
	fetchNotifications,
	markNotificationRead,
})(Notifications);
