import { arrayOf, element, elementType, func, object, oneOf, shape, string } from 'prop-types';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useTable } from 'react-table';
import { Box } from 'lundium';
import { length } from 'ramda';
import { cx, noop } from 'ramda-extension';

const Table = forwardRef(
	({ columns, data, hiddenColumns = [], onItemClick = noop, className }, ref) => {
		const instance = useTable({
			columns,
			data,
			initialState: {
				hiddenColumns,
			},
		});

		const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = instance;

		useImperativeHandle(ref, () => instance);

		return (
			<Box as="table" className={cx('table-full', className)} {...getTableProps()}>
				<Box as="thead">
					{headerGroups.map((headerGroup) => (
						<Box as="tr" {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<Box as="th" scope="col" key={column.id} {...column.getHeaderProps()}>
									{column.render('Header')}
								</Box>
							))}
						</Box>
					))}
				</Box>
				<Box as="tbody" {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row);
						const id = row.values.id;

						return (
							<Box as="tr" {...row.getRowProps()}>
								{row.cells.map((cell, index) => (
									<Box
										as="td"
										key={`${id}-${index}`}
										{...cell.getCellProps()}
										onClick={() => {
											if (index !== length(row.cells) - 1) {
												onItemClick(id);
											}
										}}
									>
										<Box>{cell.render('Cell')}</Box>
									</Box>
								))}
							</Box>
						);
					})}
				</Box>
			</Box>
		);
	}
);

Table.propTypes = {
	className: string,
	columns: arrayOf(
		shape({
			Header: oneOf([element, string]),
			accessor: string,
			Cell: elementType,
		})
	),
	data: arrayOf(object),
	hiddenColumns: arrayOf(string),
	onItemClick: func,
};

export default Table;
