import { RedirectComponent } from '@uamk/navigation';
import { Spinner } from 'lundium';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { publicNavigationItems } from './constants/navigation';
import Authenticated from './containers/Authenticated';
import WebsocketHandler from './containers/WebsocketHandler';

const Router = () => (
	<BrowserRouter>
		<RedirectComponent />
		<Suspense fallback={<Spinner />}>
			<Switch>
				{Object.values(publicNavigationItems).map((route) => (
					<Route key={route.path} {...route} />
				))}
				<Route component={Authenticated} />
			</Switch>
		</Suspense>
		<WebsocketHandler />
	</BrowserRouter>
);

export default Router;
