import { useEffect, useState } from 'react';

const useIsGtThenViewport = (breakpoint = 1280) => {
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleWindowResize = () => setWidth(window.innerWidth);
		window.addEventListener('resize', handleWindowResize);

		return () => window.removeEventListener('resize', handleWindowResize);
	}, []);

	return width > breakpoint;
};

export default useIsGtThenViewport;
