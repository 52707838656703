import React from 'react';
import { object } from 'prop-types';
import { Box } from 'lundium';
import { equals } from 'ramda';

import TableCheckbox from '../TableCheckbox';

const isTotal = equals('total');

const TableCellCheckbox = ({ row }) =>
	isTotal(row.values?.id) ? (
		<div />
	) : (
		<Box className="d-flex align-items-center">
			<Box className="form-group--checkbox form-group--checkbox-table">
				<TableCheckbox {...row.getToggleRowSelectedProps()} />
			</Box>
		</Box>
	);

TableCellCheckbox.propTypes = {
	row: object,
};

export default TableCellCheckbox;
