export const getFromLocalStorage = (key) => {
	const itemStr = localStorage.getItem(key);

	if (!itemStr) {
		return undefined;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();

	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key);

		return undefined;
	}

	return item.value;
};
