import React, { Fragment } from 'react';
import { arrayOf, node, object, oneOfType, shape, string } from 'prop-types';
import { cx, isNilOrEmpty } from 'ramda-extension';
import { test } from 'ramda';
import { Box, Icon } from 'lundium';

import { getDisplayName } from '../../utils';

const withStrengthCheck = (NextComponent) => {
	const WithStrengthCheck = ({ passwordStrengthConfig = [], ...rest }) => {
		const value = rest.input?.value;

		const passwordStrengthResults = passwordStrengthConfig.map(({ rule, ...rest }) => ({
			...rest,
			pass: test(rule, value),
		}));

		return (
			<Fragment>
				<NextComponent {...rest} />
				{!isNilOrEmpty(passwordStrengthConfig) && (
					<Box className="password-check">
						{passwordStrengthResults.map(({ message, pass }, index) => (
							<Box
								key={index}
								className={cx('password-check__item', {
									'was-validated': pass,
									'has-error': !pass,
								})}
							>
								<Box as="span" className="password-check__icon-wrapper">
									<Icon
										type={pass ? 'check' : 'close'}
										color="inherit"
										className="password-check__icon"
									/>
								</Box>
								{message}
							</Box>
						))}
					</Box>
				)}
			</Fragment>
		);
	};

	WithStrengthCheck.displayName = `withStrengthCheck(${getDisplayName(NextComponent)})`;

	WithStrengthCheck.propTypes = {
		passwordStrengthConfig: arrayOf(
			shape({ rule: string, message: oneOfType([string, node, object]) })
		),
	};

	return WithStrengthCheck;
};

export default withStrengthCheck;
