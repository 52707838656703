import React from 'react';

import ImageGallery from 'react-image-gallery';
import { Button, Icon } from 'lundium';

import { array, bool, number } from 'prop-types';

/**
 * Image Gallery
 */
const renderLeftNav = (onClick, disabled) => (
	<Button
		disabled={disabled}
		onClick={onClick}
		kind="blank"
		className="btn-icon image-gallery-button image-gallery-button--prev"
		icon={<Icon type="arrow_prev" color="inherit" />}
	/>
);

const renderRightNav = (onClick, disabled) => (
	<Button
		disabled={disabled}
		onClick={onClick}
		kind="blank"
		className="btn-icon image-gallery-button image-gallery-button--next"
		icon={<Icon type="arrow_next" color="inherit" />}
	/>
);

const Gallery = ({
	items = [],
	showFullscreenButton = false,
	showPlayButton = false,
	startIndex = 0,
}) => (
	<ImageGallery
		renderLeftNav={renderLeftNav}
		renderRightNav={renderRightNav}
		items={items}
		showThumbnails
		showFullscreenButton={showFullscreenButton}
		showPlayButton={showPlayButton}
		useBrowserFullscreen
		startIndex={startIndex}
	/>
);

Gallery.propTypes = {
	fullscreen: bool,
	items: array,
	showFullscreenButton: bool,
	showPlayButton: bool,
	startIndex: number,
};

export default Gallery;
