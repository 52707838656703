import { compose, equals, intersperse, join, map, reject, toPairs } from 'ramda';
import { rejectNil } from 'ramda-extension';

export const serializeQueryParams = compose(
	join(''),
	intersperse('&'),
	map(join('')),
	map(intersperse('=')),
	toPairs,
	reject(equals('')),
	rejectNil
);
