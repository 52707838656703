import React, { useCallback, useEffect, useRef } from 'react';
import { func, node, string } from 'prop-types';

const OutsideClickBoundary = ({ className, children, onOutsideClick }) => {
	const componentRef = useRef(null);

	/**
	 * function called when clicked on outside of element
	 */
	const handleClickOutside = useCallback(
		(event) => {
			if (componentRef && !componentRef.current.contains(event.target)) {
				onOutsideClick();
			}
		},
		[onOutsideClick]
	);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClickOutside]);

	return (
		<div className={className} ref={componentRef}>
			{children}
		</div>
	);
};

OutsideClickBoundary.propTypes = {
	children: node,
	className: string,
	onOutsideClick: func.isRequired,
};
export default OutsideClickBoundary;
