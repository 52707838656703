import { bool, shape, string } from 'prop-types';

export const notificationsApi = '/api/v1/notifications';

export const notificationShape = shape({
	id: string,
	readState: bool,
	createdAt: string,
	message: string,
});
