import React from 'react';
import { Box, Grid } from 'lundium';
import { bool, node } from 'prop-types';
import { cx } from 'ramda-extension';

const Footer = ({ children, isPrivate }) => (
	<Box as="footer" className={cx({ 'footer-private': isPrivate })}>
		<Grid container>{children}</Grid>
	</Box>
);

Footer.propTypes = {
	children: node,
	isPrivate: bool,
};

export default Footer;
