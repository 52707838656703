export * from './commonConstants';

export const defaultAuthAPIParams = {
	client_id: 'uamk-portal',
	client_secret: 'noonewilleverguess',
};

export const baseUrl = process.env.BASE_API_URL || '';

export const APP = 'partner-portal';
