// beat this
import {
	__,
	add,
	append,
	apply,
	both,
	clamp,
	complement,
	compose,
	contains,
	dec,
	equals,
	findIndex,
	gt,
	head,
	inc,
	indexOf,
	insert,
	juxt,
	last,
	lensIndex,
	lt,
	map,
	o,
	over,
	prepend,
	range,
	reduce,
	subtract,
	uniq,
	until,
	update,
	when,
} from 'ramda';
import { equalsLength, rejectEq } from 'ramda-extension';
import invariant from 'invariant';

export const LEFT_ELLIPSIS = '@@uamk/LEFT_ELLIPSIS';
export const RIGHT_ELLIPSIS = '@@uamk/RIGHT_ELLIPSIS';

const REQUIRED_FRAGMENTS_LENGTH = 5;

const notContains = complement(contains);

const createStartingRange = (pageCount, padding) =>
	compose(
		apply(range),
		over(lensIndex(-1), inc),
		map(clamp(1, pageCount)),
		juxt([subtract(__, padding), add(padding)])
	);

const prependEllipsis = when(o(gt(__, 2), head), prepend(LEFT_ELLIPSIS));

const appendEllipsis = (pageCount) => when(o(lt(__, dec(pageCount)), last), append(RIGHT_ELLIPSIS));

const safelyAddEndings = (pageCount) => compose(uniq, prepend(1), append(pageCount));

const handleOnlyLeftEllipsis = (targetLength) =>
	when(
		both(contains(LEFT_ELLIPSIS), notContains(RIGHT_ELLIPSIS)),
		until(equalsLength(targetLength), (array) => {
			const targetIndex = findIndex(equals(LEFT_ELLIPSIS), array) + 1;

			return insert(targetIndex, array[targetIndex] - 1, array);
		})
	);

const handleOnlyRightEllipsis = (targetLength) =>
	when(
		both(contains(RIGHT_ELLIPSIS), notContains(LEFT_ELLIPSIS)),
		until(equalsLength(targetLength), (array) => {
			const targetIndex = findIndex(equals(RIGHT_ELLIPSIS), array);

			return insert(targetIndex, array[targetIndex - 1] + 1, array);
		})
	);

const unneededEllipsisReducer = (array, ellipsisIndex) => {
	if (array[ellipsisIndex - 1] + 1 === array[ellipsisIndex + 1] - 1) {
		return update(ellipsisIndex, array[ellipsisIndex - 1] + 1, array);
	}

	return array;
};

/**
 * Removes all unnecessary ellipses found in an array.
 * An unnecessary ellipsis is for example [1, ELLIPSIS, 3], because the ellipsis can be 2 instead.
 */
const replaceUnneededEllipses = (array) => {
	const firstEllipsisIndex = indexOf(LEFT_ELLIPSIS, array);
	const secondEllipsisIndex = indexOf(RIGHT_ELLIPSIS, array);
	const indices = rejectEq(-1, [firstEllipsisIndex, secondEllipsisIndex]);

	return reduce(unneededEllipsisReducer, array, indices);
};

/**
 * Creates a pagination descriptor with a fixed with, placing string ellipses where necessary.
 *
 * @param {Number} pageNumber currently active page number (starts with 1)
 * @param {Number} pageCount number of pages
 * @param {Number} [padding=1] minimum padding of the active page
 * @returns {Array} pagination descriptor
 *
 * @example
 * 	makePaginationDescriptor(10, 30, 2) // [1, LEFT_ELLIPSIS, 8, 9, 10, 11, 12, RIGHT_ELLIPSIS, 30]
 */
export const makePaginationDescriptor = (pageNumber, pageCount, padding = 1) => {
	invariant(pageNumber != null, 'Page number must be defined.');
	invariant(pageCount != null, 'Page count must be defined.');
	invariant(pageNumber <= pageCount, 'Page number cannot be greater than page count.');
	invariant(pageNumber >= 0, 'Page number must be positive');
	invariant(pageCount >= 0, 'Page count must be positive');

	const targetLength = 2 * padding + REQUIRED_FRAGMENTS_LENGTH;

	return pageCount <= targetLength
		? range(1, pageCount + 1)
		: compose(
				replaceUnneededEllipses,
				handleOnlyRightEllipsis(targetLength),
				handleOnlyLeftEllipsis(targetLength),
				safelyAddEndings(pageCount),
				appendEllipsis(pageCount),
				prependEllipsis,
				createStartingRange(pageCount, padding)
		  )(pageNumber);
};
