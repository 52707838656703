import React, { useEffect } from 'react';
import { func, shape, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { change } from 'redux-form';

import { withFormField } from '../../utils';
import RadioGroup from '../RadioGroup';
import m from '../../messages';

const options = [
	{ value: 'true', label: <FormattedMessage {...m.yesLabel} /> },
	{ value: 'false', label: <FormattedMessage {...m.noLabel} /> },
	{ value: 'undefined', label: <FormattedMessage {...m.unspecifiedLabel} /> },
];

const YesNoRadioField = ({
	input: { name, ...input },
	meta: { dispatch, form, ...meta },
	initialValue = input.value ?? 'undefined',
	...props
}) => {
	useEffect(() => {
		dispatch(change(form, name, initialValue));
	}, [initialValue, name, form, dispatch]);

	return (
		<RadioGroup
			options={options}
			{...props}
			input={{ ...input, name }}
			meta={{ ...meta, dispatch, form }}
		/>
	);
};

YesNoRadioField.propTypes = {
	initialValue: string,
	input: shape({ name: string }),
	meta: shape({ dispatch: func, form: string }),
};

export default withFormField(YesNoRadioField);
