import React from 'react';
import {
	composeMiddleware,
	makeActionTypes,
	makePayloadActionCreator,
	makePayloadMetaActionCreator,
} from '@redux-tools/react';
import { Routes, navigate } from '@uamk/navigation';
import { addNotification, type } from '@uamk/notifications';
import { makeMiddleware, typeEq } from '@uamk/utils';
import { isApiumError, isApiumSuccess, request } from 'apium';
import { anyPass } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { reset } from 'redux-form';

import m from '../messages';
import { fetchToken, getUserName } from './authentication';

const ActionTypes = makeActionTypes('@passwordReset', ['RESET_PASSWORD', 'CHANGE_PASSWORD']);

export const resetPassword = makePayloadActionCreator(ActionTypes.RESET_PASSWORD);
export const changePassword = makePayloadMetaActionCreator(ActionTypes.CHANGE_PASSWORD);

const usersAPIPath = '/api/v1/users';

const resetPasswordMiddleware = makeMiddleware(
	typeEq(ActionTypes.RESET_PASSWORD),
	({ dispatch }) => (action) =>
		dispatch(
			request(
				{
					method: 'POST',
					url: `${usersAPIPath}/passwordReset`,
					body: action.payload,
				},
				{ origin: action }
			)
		)
);

const changePasswordMiddleware = makeMiddleware(
	typeEq(ActionTypes.CHANGE_PASSWORD),
	({ dispatch }) => (action) =>
		dispatch(
			request(
				{
					method: 'POST',
					url: `${usersAPIPath}/changePassword`,
					body: action.payload,
				},
				{ origin: action }
			)
		)
);

const resetPasswordSuccessMiddleware = makeMiddleware(
	isApiumSuccess(ActionTypes.RESET_PASSWORD),
	({ dispatch }) => ({ meta: { origin } }) => {
		dispatch(
			addNotification({
				message: <FormattedMessage {...m.newPasswordSent} values={origin.payload} />,
				type: type.SUCCESS,
			})
		);
		dispatch(navigate({ path: Routes.login }));
	}
);

const changePasswordSuccessMiddleware = makeMiddleware(
	isApiumSuccess(ActionTypes.CHANGE_PASSWORD),
	({ dispatch, getState }) => ({ meta }) => {
		const username = getUserName(getState());
		const password = meta?.origin?.payload?.newPassword;
		const form = meta?.origin?.meta?.form;

		dispatch(
			addNotification({
				message: <FormattedMessage {...m.passwordChanged} />,
				type: type.SUCCESS,
			})
		);
		dispatch(fetchToken({ grant_type: 'password', username, password }));
		if (form) {
			dispatch(reset(form));
		}
	}
);

const resetPasswordErrorMiddleware = makeMiddleware(
	anyPass([isApiumError(ActionTypes.RESET_PASSWORD), isApiumError(ActionTypes.CHANGE_PASSWORD)]),
	({ dispatch }) => ({ payload }) => {
		dispatch(
			addNotification({
				message: payload.error_description || <FormattedMessage {...m.apiError} />,
				type: type.ERROR,
			})
		);
	}
);

export const passwordResetMiddleware = composeMiddleware(
	resetPasswordMiddleware,
	resetPasswordSuccessMiddleware,
	resetPasswordErrorMiddleware,
	changePasswordMiddleware,
	changePasswordSuccessMiddleware
);
