import React from 'react';
import { bool, func, node, shape, string } from 'prop-types';
import { Box } from 'lundium';
import { noop } from 'ramda-extension';

const Item = ({ identifier, item, selectItem }) => (
	<Box
		className="multiselect--checkbox"
		onClick={(event) => {
			event.preventDefault();
			selectItem(item);
		}}
	>
		<input
			id={`${item.value}-${identifier}`}
			type="checkbox"
			className="form-control"
			// defaultChecked does not work with 'check' attribute in styles.
			checked={item.selected || false}
			// To avoid warning message about onChange function is needed.
			onChange={noop}
		/>
		<label className="control-label" htmlFor={`${item.value}-${identifier}`}>
			{item.label}
		</label>
	</Box>
);

Item.propTypes = {
	identifier: string,
	item: shape({
		value: string.isRequired,
		label: node,
		selected: bool,
	}).isRequired,
	selectItem: func.isRequired,
};

export default Item;
