import React from 'react';
import { Box, Button, Icon, Text } from 'lundium';
import { func, node, shape, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import m from '../../messages';

const ConfirmModal = ({ actions: { closeModal }, data: { label, route } }) => {
	const history = useHistory();

	return (
		<Box className="modal-body">
			<Box className="modal-body-inner">
				<Box className="modal-heading">
					<Box as="span" className="badge badge--info">
						<Icon type="alert" color="white" />
					</Box>
					<Text as="h2" mb={0}>
						<FormattedMessage {...m.confirmModalTitle} />
					</Text>
				</Box>
				<Box className="modal-wrapper">
					<Text className="text-secondary-medium text-center" mb={1}>
						<FormattedMessage {...m.confirmModalSubtitle} values={{ label }} />
					</Text>
				</Box>
				<Box className="modal-footer">
					<Button
						className="text-uppercase"
						onClick={() => {
							history.push(route);
							closeModal();
						}}
					>
						<FormattedMessage {...m.confirmModalLeaveLabel} />
					</Button>
					<Button kind="primary" className="text-uppercase" onClick={() => closeModal()}>
						<FormattedMessage {...m.confirmModalStayLabel} />
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

ConfirmModal.propTypes = {
	actions: shape({ closeModal: func }),
	data: shape({
		label: node,
		route: string,
	}),
};

export default ConfirmModal;
