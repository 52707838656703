import { eqProps, isEmpty, not, o, tail } from 'ramda';
import { makeActionTypes, makeEmptyActionCreator, makeReducer, makeSimpleActionCreator } from '@redux-tools/react';

import { eqPropsArray } from './utils';

// ACTIONS
export const actions = makeActionTypes('@modal', [
	'OPEN',
	'CLOSE',
	'CLOSE_ALL',
	'UPDATE_DATA',
	'CLEAN_CONTAINER',
]);

/**
 * Action creator - Open modal windows with provided ID
 * @param managerId - ID of the modal manager
 * @param modalId - ID of the modal
 */
export const openModal = makeSimpleActionCreator(actions.OPEN);

export const closeModal = makeEmptyActionCreator(actions.CLOSE);

export const closeModalAll = makeEmptyActionCreator(actions.CLOSE_ALL);

/**
 * Action creator - Updates modal initial state data
 * @param managerId - Modal window manager ID
 * @param modalId - Modal ID
 * @param data - Initial state
 */
export const updateModalData = makeSimpleActionCreator(actions.UPDATE_DATA);

/**
 * Action creator - Cleans container after unmount
 * @param managerId - Unique modal manager ID
 */
export const cleanModalContainer = makeSimpleActionCreator(actions.CLEAN_CONTAINER);

const initialState = [];

export const reducerModals = makeReducer(
	[
		[actions.OPEN, (state, { payload }) => [payload, ...state]],
		[actions.CLOSE, (state) => tail(state)],
		[actions.CLOSE_ALL, () => []],
		[
			actions.UPDATE_DATA,
			(state, { payload }) =>
				state.map((modal) =>
					eqPropsArray(['managerId', 'modalId'], payload, modal) ? payload : modal
				),
		],
		[
			actions.CLEAN_CONTAINER,
			(state, { payload }) => state.filter(o(not, eqProps('managerId', payload))),
		],
	],
	initialState
);

export const isModalActive = ({ modals }) => !isEmpty(modals);
export const getCurrentModal = ({ modals }) => modals[0];
