import { any, propEq } from 'ramda';

const getSelectedRowIds = (data, selectedRows = []) => {
	const selectedInData = selectedRows.filter((id) => any(propEq('id', id), data));
	const reduced = selectedInData.reduce((result, item) => {
		result[item] = true;

		return result;
	}, {});

	return reduced;
};

export default getSelectedRowIds;
