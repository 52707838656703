import React from 'react';
import { any, bool, node, number, object, oneOfType, string } from 'prop-types';
import { isEmpty, o } from 'ramda';
import { cx } from 'ramda-extension';
import { FormattedMessage } from 'react-intl';

import { withFormField, withGeneratedId } from '../../utils';

export const TextArea = ({
	id,
	generatedId,
	disabled,
	label,
	className,
	maxLength = 120,
	input = {},
	meta: { active, error, touched } = {},
}) => (
	<div
		className={cx('form-group', 'form-group--text', className, {
			'is-focused': active,
			'has-value': !isEmpty(input.value),
			'has-error': error && touched,
			disabled,
		})}
	>
		<label htmlFor={id || generatedId} className="label">
			{label}
		</label>
		<textarea className="form-control" id={id || generatedId} maxLength={maxLength} {...input} />
		<div className="form-group__text">{`${input?.value?.length || '0'}/${maxLength}`}</div>
		{error && touched && (
			<div className="invalid-feedback">
				<FormattedMessage {...error.message} values={error.messageValues} />
			</div>
		)}
	</div>
);

TextArea.propTypes = {
	className: string,
	disabled: bool,
	generatedId: string,
	id: string,
	input: any,
	label: oneOfType([string, node, object]),
	maxLength: number,
	meta: any,
	name: string.isRequired,
};

export default o(withGeneratedId, withFormField)(TextArea);
