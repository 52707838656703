import React, { Fragment, memo, useCallback, useEffect } from 'react';
import { bool, func, string } from 'prop-types';
import {
	getUserPartnerId,
	isAccessTokenExpired,
	isAuthorized,
	isTokenFetchingPending,
	refreshToken,
} from '@uamk/domain-auth';
import { Routes } from '@uamk/navigation';
import { addNotification, removeNotification, type } from '@uamk/notifications';
import { CountDown } from '@uamk/ui-components';
import {
	forceRefetch,
	subscribe,
	connect as wsConnect,
	disconnect as wsDisconnect,
} from '@uamk/websockets';
import { getTimeDiffMillis, isMobileDevice } from '@uamk/utils';
import { connect } from 'react-redux';
import { applySpec, o } from 'ramda';
import { noop } from 'ramda-extension';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Box, Icon, Text } from 'lundium';

import m from '../messages';
import { notificationSoundFile, notificationTypes } from '../constants';

const notificationSound = new Audio(notificationSoundFile);
const domEvent = isMobileDevice() ? 'touchstart' : 'click';

const unlockSound = () => {
	notificationSound.play().catch(noop);
	notificationSound.pause();
	notificationSound.currentTime = 0;
	document.removeEventListener(domEvent, unlockSound);
};

const WebsocketHandler = ({
	isAccessTokenExpired,
	isAuthorized,
	isTokenFetchingPending,
	partnerId,
	refreshToken,
	removeNotification,
	subscribe,
	wsConnect,
	wsDisconnect,
	addNotification,
	forceRefetch,
}) => {
	const history = useHistory();

	// .... START Safari, Firefox and other browsers workaround to autoplay Audio (without user interaction), like when ws notification is received
	useEffect(() => {
		document.addEventListener(domEvent, unlockSound);

		return () => document.removeEventListener(domEvent, unlockSound);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// .... END Safari, Firefox and other browsers workaround to autoplay Audio (without user interaction), like when ws notification is received

	const getNewNotificationProps = useCallback(({ reactionLimit, message }) => {
		const millisLeft = getTimeDiffMillis(new Date(reactionLimit));

		return {
			showCloseBtn: false,
			message: (
				<Fragment>
					{message}
					<Box mt={1}>
						<Icon type="timeout" color="inherit" mr={1} />
						<Text as="strong">
							<CountDown milliseconds={millisLeft} />
						</Text>
					</Box>
				</Fragment>
			),
			hideAfter: millisLeft,
		};
	}, []);

	useEffect(() => {
		if (isAuthorized && partnerId && !isAccessTokenExpired) {
			wsConnect();
			subscribe({
				path: `/api/v1/partnerRoom/${partnerId}`,
				callback: (wssMessage) => {
					const body = JSON.parse(wssMessage.body ?? '');
					const { message, notificationType, reactionLimit } = body;
					const { id } = body.incident;

					if (notificationType === notificationTypes.NEW) {
						notificationSound.play().catch(() => console.error('Cannot play notification sound'));
					} else if (notificationType === notificationTypes.TIMED_OUT) {
						removeNotification(id);
					}

					addNotification({
						type: type.INFO,
						onClickAction: () =>
							history.push({
								pathname: `${Routes.incidents}/${id}`,
								state: { backPath: Routes.incidents },
							}),
						labelAction: <FormattedMessage {...m.notificationShowDetailBtn} />,
						id,
						message,
						...(notificationType === notificationTypes.NEW
							? getNewNotificationProps({ reactionLimit, message })
							: {}),
					});
					forceRefetch(true);
				},
			});
		}

		if (isAuthorized && isAccessTokenExpired && !isTokenFetchingPending) {
			refreshToken();
		}

		if (!isAuthorized || isAccessTokenExpired) {
			wsDisconnect();
		}
	}, [
		addNotification,
		history,
		isAccessTokenExpired,
		isAuthorized,
		isTokenFetchingPending,
		partnerId,
		refreshToken,
		getNewNotificationProps,
		removeNotification,
		subscribe,
		wsConnect,
		wsDisconnect,
		forceRefetch,
	]);

	return <Fragment />;
};

WebsocketHandler.propTypes = {
	addNotification: func,
	forceRefetch: func,
	isAccessTokenExpired: bool,
	isAuthorized: bool,
	isTokenFetchingPending: bool,
	partnerId: string,
	refreshToken: func,
	removeNotification: func,
	subscribe: func,
	wsConnect: func,
	wsDisconnect: func,
};

export default o(
	connect(
		applySpec({
			isAccessTokenExpired,
			isAuthorized,
			isTokenFetchingPending,
			partnerId: getUserPartnerId,
		}),
		{
			addNotification,
			forceRefetch,
			refreshToken,
			removeNotification,
			subscribe,
			wsConnect,
			wsDisconnect,
		}
	),
	memo
)(WebsocketHandler);
