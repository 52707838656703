import React from 'react';
import { arrayOf, func } from 'prop-types';
import { NotificationStack } from 'lundium';
import { applySpec, o } from 'ramda';
import { connect } from 'react-redux';
import { withReducers } from '@redux-tools/react';

import notificationsReducer, { getAllNotifications, removeNotification } from './duck';

import Notification from './components/Notification';

const Notifications = ({ notifications = [], removeNotification }) => (
	<NotificationStack
		className="notifications-container"
		notifications={notifications}
		renderNotification={({ id, message, ...rest }) => (
			<Notification id={id} {...rest} closeNotification={() => removeNotification(id)}>
				{message}
			</Notification>
		)}
	/>
);

Notifications.propTypes = {
	notifications: arrayOf(Notification.propTypes),
	removeNotification: func,
};

export default o(
	withReducers({ notifications: notificationsReducer }, { isGlobal: true, isPersistent: true }),
	connect(
		applySpec({
			notifications: getAllNotifications,
		}),
		{
			removeNotification,
		}
	)
)(Notifications);
