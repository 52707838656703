/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { func } from 'prop-types';
import { noop } from 'ramda-extension';
import { usePrevious } from '@uamk/utils';

import { apiPaginationShape } from '../../constants';

/**
 *
 * Use only for tables without filters.
 *
 * @param {*} param0
 * @returns
 */
const useNonFilteredTable = ({
	pagination = {},
	fetchResource = noop,
	afterFetchAction = noop,
	fetchEqualsMeta = false,
}) => {
	const [page, setPage] = useState(pagination?.pageNumber ?? 0);
	const [pageSize, setPageSize] = useState(pagination?.pageSize ?? 10);
	const [pageResetting, setPageResetting] = useState(false);
	const previousPageSize = usePrevious(pageSize);

	useEffect(() => {
		if (!pageResetting) {
			if (fetchEqualsMeta) {
				fetchResource(
					{
						page: pageSize !== previousPageSize && previousPageSize !== undefined ? 0 : page,
						size: pageSize,
					},
					{}
				);
			} else {
				fetchResource({
					page: pageSize !== previousPageSize && previousPageSize !== undefined ? 0 : page,
					size: pageSize,
				});
			}
		}

		if (pageResetting) {
			setPageResetting(false);
		}

		if (pageSize !== previousPageSize && page !== 0 && previousPageSize !== undefined) {
			setPageResetting(true);
			setPage(0);
		}

		afterFetchAction();
	}, [page, pageSize, fetchResource]);

	return {
		setPage,
		setPageSize,
	};
};

useNonFilteredTable.propTypes = {
	afterFetchAction: func,
	fetchResource: func,
	pagination: apiPaginationShape,
};

export default useNonFilteredTable;
