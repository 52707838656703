import { concat, endsWith, init, isEmpty, o, unless, useWith, when } from 'ramda';
import { defaultToEmptyString, isString } from 'ramda-extension';

import { serializeQueryParams } from './serializeQueryParams';

// eslint-disable-next-line react-hooks/rules-of-hooks
export const appendQueryParams = useWith(concat, [
	o(when(endsWith('?'), init), defaultToEmptyString),
	o(unless(isEmpty, concat('?')), unless(isString, serializeQueryParams)),
]);
