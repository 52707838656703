export const readFileAsText = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () =>
			resolve({
				name: file?.name,
				path: file?.path,
				size: file?.size,
				type: file?.type,
				content: reader.result,
			});
		reader.onerror = () => reject(reader);
		reader.readAsText(file);
	});
