import React, { useMemo } from 'react';
import { any, arrayOf, bool, element, oneOfType, shape, string } from 'prop-types';
import { o } from 'ramda';
import { cx, isNilOrEmptyString } from 'ramda-extension';
import { FormattedMessage, useIntl } from 'react-intl';
import { isObjectMessage } from '@uamk/utils';

import { withFormField, withGeneratedId } from '../../utils';

const formatLabel = (label, format) =>
	isObjectMessage(label?.props) ? format(label.props) : label;

export const SelectField = ({
	id,
	generatedId,
	label,
	options = [],
	className,
	disabled,
	meta: { active, error, touched } = {},
	input,
	enableUnselectOption = true,
	...rest
}) => {
	const intl = useIntl();
	const fieldId = id || generatedId;

	const optionsMemoized = useMemo(
		() => (enableUnselectOption ? [{ value: '', label: '' }, ...options] : options),
		[enableUnselectOption, options]
	);

	return (
		<div
			className={cx('form-group', 'form-group--select', className, {
				disabled,
				'has-error': error && touched,
				'has-value': !isNilOrEmptyString(input.value) && !active,
				'is-focused': active,
			})}
		>
			<label htmlFor={fieldId}>{label}</label>
			<select className="form-control" id={fieldId} {...rest} {...input} readOnly>
				<option value="" className="d-none" />
				{optionsMemoized.map(({ label, value }, index) => (
					<option key={index} value={value}>
						{formatLabel(label, intl.formatMessage)}
					</option>
				))}
			</select>
			{error && touched && (
				<div className="invalid-feedback">
					<FormattedMessage {...error.message} values={error.messageValues} />
				</div>
			)}
		</div>
	);
};

SelectField.propTypes = {
	className: string,
	disabled: bool,
	enableUnselectOption: bool,
	generatedId: string,
	id: string,
	input: any,
	label: oneOfType([string, element]),
	meta: any,
	options: arrayOf(
		shape({
			label: string,
			value: string,
		})
	).isRequired,
	showFirstAsDefault: bool,
};

export default o(withGeneratedId, withFormField)(SelectField);
