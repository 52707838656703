import { bool, func, shape } from 'prop-types';

export const modalActionPropTypes = {
	closeModal: func.isRequired,
	closeModalAll: func.isRequired,
	openModal: func.isRequired,
};

export const modalWrapperPropTypes = {
	actions: shape({
		...modalActionPropTypes,
	}),
	isActive: bool,
};

export const modalContentPropTypes = {
	actions: shape({
		...modalActionPropTypes,
	}),
};
