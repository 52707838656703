import React from 'react';
import { Box } from 'lundium';
import { any, string } from 'prop-types';

import { withGeneratedId } from '../../utils';

const RadioField = ({ generatedId, input, label, value }) => (
	<Box className="form-group--radio">
		<input
			{...input}
			type="radio"
			className="form-control"
			id={generatedId}
			value={value}
			checked={value === input.value}
		/>
		<label className="control-label" htmlFor={generatedId}>
			{label}
		</label>
	</Box>
);

RadioField.propTypes = {
	generatedId: string,
	input: any,
	label: string,
	value: any,
};

export default withGeneratedId(RadioField);
