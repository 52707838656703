import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Grid, Icon } from 'lundium';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import { cx } from 'ramda-extension';

import Footer from '../Footer';
import NavPrimary from '../NavPrimary';
import NavSecondary from '../NavSecondary';
import Backdrop from '../Backdrop';
import Logo from '../../assets/images/layout/logo.svg';
import LogoSquare from '../../assets/images/layout/logoSquare.svg';
import m from '../../messages';

const PrivateLayout = ({
	children,
	items,
	hasStepper,
	logout,
	name,
	profilePath,
	notifications,
	notificationProps = {},
}) => {
	const [isMenuOpen, setOpenMenu] = useState(false);
	const [isTabletMenuOpen, setOpenTabletMenu] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const backPath = location.state?.backPath;
	const year = new Date().getFullYear();

	useEffect(() => {
		setOpenMenu(false);
	}, [location]);

	const handleLogout = () => logout();
	const handleBackBtn = () =>
		typeof backPath === 'string' ? history.push(backPath) : history.goBack();

	const handleOnProfileClick = () =>
		history.push({ pathname: profilePath, state: { fromProfile: true } });

	return (
		<Box
			className={cx('layout', {
				'aside--toggle-open': isTabletMenuOpen,
				'layout--with-stepper': hasStepper,
			})}
		>
			<Box as="header" className={cx('header-private', { 'header--toggle-open': isMenuOpen })}>
				<Logo className="logo d-md-none" />
				<Box as="span" className="nav-toggle d-md-none" onClick={() => setOpenMenu(!isMenuOpen)}>
					<Box as="span" className="nav-toggle__label">
						<FormattedMessage {...m.menu} />
					</Box>
					<Icon type="menu" color="white" />
				</Box>
				<Grid container className="d-none d-md-flex" px={{ xl: 4 }}>
					{backPath && (
						<Button
							icon={<Icon type="arrow_big_left" color="inherit" mr={2} />}
							onClick={handleBackBtn}
						>
							<FormattedMessage {...m.back} />
						</Button>
					)}
					<NavSecondary
						name={name}
						logout={handleLogout}
						onProfileClick={handleOnProfileClick}
						notifications={notifications}
						notificationProps={notificationProps}
					/>
				</Grid>
			</Box>
			<Box as="aside" className={cx('aside', { 'aside--toggle-open': isTabletMenuOpen })}>
				<Box className="aside__logo-holder">
					<LogoSquare className={cx('aside__logo d-xl-none', { 'd-none': isTabletMenuOpen })} />
					<Logo className={cx('aside__logo d-xl-block', { 'd-none': !isTabletMenuOpen })} />
				</Box>
				<NavPrimary items={items} />
				<NavSecondary
					name={name}
					logout={handleLogout}
					onProfileClick={handleOnProfileClick}
					notifications={notifications}
					notificationProps={notificationProps}
				/>
				<Box
					as="span"
					className="aside__toggle-btn"
					onClick={() => setOpenTabletMenu(!isTabletMenuOpen)}
				>
					<Icon type={isTabletMenuOpen ? 'arrow_prev' : 'arrow_next'} color="white" />
				</Box>
			</Box>
			<Backdrop isOpen={isMenuOpen} />
			<Box className="content">
				{backPath && (
					<Box className="text-uppercase d-md-none bg-white pt-1">
						<Box className="container">
							<Button
								icon={<Icon type="arrow_big_left" color="inherit" mr={2} className="ml-n2" />}
								onClick={handleBackBtn}
							>
								Zpět
							</Button>
						</Box>
					</Box>
				)}
				{children}
			</Box>
			<Footer isPrivate>
				<FormattedMessage {...m.copyright} values={{ year }} />
			</Footer>
		</Box>
	);
};

PrivateLayout.propTypes = {
	children: node,
	hasStepper: bool,
	items: arrayOf(
		shape({
			icon: string,
			label: string,
			path: string,
		})
	),
	logout: func,
	name: string,
	notificationProps: object,
	notifications: arrayOf(
		shape({ id: string, readState: bool, createdAt: string, message: string })
	),
	profilePath: string,
};

export default PrivateLayout;
