import React from 'react';
import { Field } from 'redux-form';

import getDisplayName from './getDisplayName';

const withFormField = (NextComponent) => {
	const WithFormField = (props) => <Field component={NextComponent} {...props} />;

	WithFormField.displayName = `withFormField(${getDisplayName(NextComponent)})`;

	return WithFormField;
};

export default withFormField;
