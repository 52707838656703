import { compose } from 'ramda';

import { withFormField, withGeneratedId } from '../../utils';
import { PasswordField } from '../PasswordField/PasswordField';

import withStrengthCheck from './withStrengthCheck';

// NOTE for test purposes
export const PasswordStrenghtCheckField = withStrengthCheck(PasswordField);

export default compose(withGeneratedId, withFormField)(PasswordStrenghtCheckField);
