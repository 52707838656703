export const Routes = {
	root: '/',
	login: '/login',
	reset: '/reset',
	password: '/password',
	incidents: '/incidents',
	users: '/users',
	technics: '/technics',
	economics: '/economics',
	pricelists: '/pricelists',
	authors: '/authors',
	notifications: '/notifications',
	profile: '/profile',
};
