import React, { createContext, useState } from 'react';
import { node } from 'prop-types';
import { append, filter, ifElse, includes, isEmpty, map, o, without } from 'ramda';
import { noop, rejectEq } from 'ramda-extension';

import { getDisplayName } from '../../utils';

export const TableSelectionContext = createContext({
	selectAll: false,
	setSelectAll: noop,
	selectedItems: [],
	setSelectedItems: noop,
	toggleSelectedItem: noop,
	toggleSelectAll: noop,
	includeAndRemoveItems: noop,
	allDeselected: false,
	setAllDeselected: noop,
	checkSelectAll: noop,
	clearContext: noop,
});

/**
 *
 * @param {Array} selectedIds array of primitive values
 * @returns Array of primitive values
 */
const filterItems = (selectedIds) =>
	o(
		map(({ id }) => id),
		filter(({ id }) => !selectedIds.includes(id))
	);

export const TableSelectionContextProvider = ({ children }) => {
	const [selectedItems, setSelectedItems] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [allDeselected, setAllDeselected] = useState(false);

	// Toggle single selected/deselected row
	const toggleSelectedItem = (id, data) => {
		const newlySelectedItem = ifElse(includes(id), rejectEq(id), append(id))(selectedItems);
		setSelectedItems(newlySelectedItem);

		const notIncludedItems = filterItems(newlySelectedItem)(data);
		setSelectAll(isEmpty(notIncludedItems)); // mark select all when no technics left to be included
	};

	// Toggle selected all checkbox
	const toggleSelectAll = (data) => {
		if (!selectAll) {
			const newItems = filterItems(selectedItems)(data);
			setSelectedItems([...selectedItems, ...newItems]);
		} else {
			const itemsToRemove = map(({ id }) => id)(data);
			setSelectedItems(without(itemsToRemove, selectedItems));
		}
		setSelectAll(!selectAll);
	};

	const includeAndRemoveItems = (toBeRemoved = [], toBeIncluded = []) => {
		const clearedTechnics = without(toBeRemoved, selectedItems);
		setSelectedItems([...clearedTechnics, ...toBeIncluded]);
	};

	const checkSelectAll = (data) => {
		const notIncludedItems = filterItems(selectedItems)(data);
		setSelectAll(isEmpty(notIncludedItems)); // mark select all when no technics left to be included
	};

	const clearContext = () => {
		setAllDeselected(true);
		setSelectedItems([]);
		setSelectAll(false);
	};

	return (
		<TableSelectionContext.Provider
			value={{
				selectAll,
				setSelectAll,
				selectedItems,
				setSelectedItems,
				toggleSelectedItem,
				toggleSelectAll,
				includeAndRemoveItems,
				allDeselected,
				setAllDeselected,
				checkSelectAll,
				clearContext,
			}}
		>
			{children}
		</TableSelectionContext.Provider>
	);
};

TableSelectionContextProvider.propTypes = {
	children: node,
};

export const withTableSelectionContext = (NextComponent) => {
	const WithTableSelectionContext = (props) => (
		<TableSelectionContextProvider>
			<NextComponent {...props} />
		</TableSelectionContextProvider>
	);

	WithTableSelectionContext.displayName = `withTableSelectionContext(${getDisplayName(
		NextComponent
	)})`;

	return WithTableSelectionContext;
};
