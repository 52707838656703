import invariant from 'invariant';
import { isFunction } from 'ramda-extension';

const signature = '(Action => boolean, ({ dispatch, getState }) => Action => void) => Middleware';

export const makeMiddleware = (predicate, middleware) => (reduxAPI) => {
	const boundMiddleware = middleware(reduxAPI);

	invariant(
		isFunction(boundMiddleware) && isFunction(predicate),
		`Signature mismatch, are you using "makeMiddleware" correctly? Signature: ${signature}`
	);

	return (next) => (action) => {
		next(action);

		if (predicate(action)) {
			const value = boundMiddleware(action);

			invariant(
				!isFunction(value),
				`When using "makeMiddleware", you should omit the "next" curried argument. Signature: ${signature}`
			);
		}
	};
};
