import React from 'react';
import { func, shape, string } from 'prop-types';
import { Box, Icon, Link } from 'lundium';

const UploadBoxItem = ({ file: { fileName = '', url }, action }) => (
	<Box className="upload-box__item">
		<Link className="upload-box__item-label" href={url} target="_blank">
			{fileName}
		</Link>
		<Icon type="close" color="primary" onClick={action} />
	</Box>
);

UploadBoxItem.propTypes = {
	action: func,
	file: shape({
		fileName: string,
		url: string,
	}),
};

export default UploadBoxItem;
