/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { arrayOf, bool, object, string } from 'prop-types';
import { equals, filter, isEmpty, map, o, toString, type, union } from 'ramda';
import { cx, isArray, isNilOrEmptyString } from 'ramda-extension';
import { FormattedMessage, useIntl } from 'react-intl';
import invariant from 'invariant';
import { usePrevious } from '@uamk/utils';

import OutsideClickBoundary from '../OutsideClickBoundary';
import { withFormField, withGeneratedId } from '../../utils';
import m from '../../messages';

import { getSelected, isSelected, setDefaultSelections } from './utils';
import Item from './Item';

const mapValue = map(({ value }) => value);
const filterSelectedValues = o(mapValue, filter(isSelected));

/**
 * Technical documentation: There is three levels here:
 *
 * 1. header - an string to display in the header, check getSelectedValues() method (also should support search)
 *
 * 2. options - [{label, value}] = raw values
 *    items - displayable values = added selected attribute to control selected items
 *
 * 3. defaults, form values = selected values (items with selected = true).
 *
 *
 * should support, update options.
 * take into account enableSelectAll and defaultSelectedAll.
 */
export const MultiSelect = ({
	id,
	generatedId,
	label,
	options = [],
	enableSelectAll,
	defaultSelectedAll,
	meta: { active, error, touched } = {},
	input: { onChange, onFocus, onBlur, name, value: defaults = [] } = {},
	disabled,
	className,
}) => {
	invariant(isArray(defaults), `Type of "defaults" is ${type(defaults)}, An Array is expected.`);
	const belongToOptions = useCallback(
		(formValue) => options.find(({ value }) => value === formValue),
		[options]
	);
	invariant(
		defaults.every(belongToOptions),
		`One value of ${defaults} is not in options ${toString(mapValue(options))}`
	);

	const [isOpen, setIsOpen] = useState(false);
	const [items, setItems] = useState([]);
	const intl = useIntl();
	const prevItems = usePrevious(items);
	const fieldId = id || generatedId;
	const allSelected = items.every(isSelected);

	const getSelectedValues = useMemo(
		() =>
			getSelected({
				allSelected: intl.formatMessage(m.multiSelectAll),
				manySelected: intl.formatMessage(m.multiSelectManySelected),
			}),
		[intl]
	);

	useEffect(() => {
		const all = defaultSelectedAll && enableSelectAll && isEmpty(defaults) ? mapValue(options) : [];
		setItems(setDefaultSelections(union(all, defaults))(options));
	}, [options]);

	useEffect(() => {
		const previousSelectedValues = filterSelectedValues(prevItems || []);
		if (!equals(defaults.sort(), previousSelectedValues.sort())) {
			setItems((currentItems) => setDefaultSelections(defaults)(currentItems));
		}
	}, [defaults]);

	useEffect(() => {
		const selectedValues = filterSelectedValues(items);
		if (!equals(defaults.sort(), selectedValues.sort()) && prevItems !== undefined) {
			onChange(selectedValues);
		}
	}, [items]);

	useEffect(() => {
		if (isOpen) {
			onFocus();
		}
	}, [isOpen, onFocus]);

	const selectItem = ({ value }) => {
		setItems((currentItems) =>
			currentItems.map((item) =>
				value === item.value ? { ...item, selected: !item?.selected } : item
			)
		);
	};

	const closeMultiSelect = () => {
		setIsOpen(false);
		if (active) {
			onBlur();
		}
	};
	const toogleList = () => setIsOpen(!isOpen);

	const toggleSelectAllItems = () =>
		setItems((currentItems) => currentItems.map((item) => ({ ...item, selected: !allSelected })));

	return (
		<OutsideClickBoundary onOutsideClick={closeMultiSelect}>
			<div
				className={cx('form-group form-group--select', className, {
					'has-error': error && touched,
					'has-value': !isNilOrEmptyString(getSelectedValues(items)),
					'is-focused': active,
					disabled,
				})}
			>
				<label htmlFor={fieldId}>{label}</label>
				<input
					id={fieldId}
					name={name}
					onClick={toogleList}
					className="form-control"
					value={getSelectedValues(items)}
					autoComplete="off"
					readOnly
				/>
				{error && touched && (
					<div className="invalid-feedback">
						<FormattedMessage {...error.message} values={error.messageValues} />
					</div>
				)}

				{isOpen && (
					<div className="multiselect-list">
						{enableSelectAll && (
							<Item
								identifier="multiselect-item-all"
								item={{
									value: 'multiselect-item-all',
									label: intl.formatMessage(m.multiSelectAll),
									selected: allSelected,
								}}
								selectItem={toggleSelectAllItems}
							/>
						)}
						{items.map((item, index) => (
							<Item key={index} identifier={index} item={item} selectItem={selectItem} />
						))}
					</div>
				)}
			</div>
		</OutsideClickBoundary>
	);
};

MultiSelect.propTypes = {
	className: string,
	defaultSelectedAll: bool,
	disabled: bool,
	enableSelectAll: bool,
	generatedId: string,
	id: string,
	input: object,
	label: string,
	meta: object,
	name: string.isRequired,
	options: arrayOf(string).isRequired,
};

export default o(withGeneratedId, withFormField)(MultiSelect);
