import { apiumReducer, makeApiumMiddleware } from 'apium';
import { identity } from 'ramda';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { reducer as formReducer } from 'redux-form';
import { reducerModals as modalsReducer } from '@uamk/modals';
import { navigationReducer } from '@uamk/navigation';
import { authReducer, makeAuthenticationMiddleware } from '@uamk/domain-auth';
import { notificationReducer, notificationsMiddleware } from '@uamk/domain-notifications';
import { makePersistorMiddleware } from '@uamk/persistor';
import { makeWebsocketMiddleware, websocketsReducer } from '@uamk/websockets';
import { makeMiddlewareEnhancer, makeReducersEnhancer } from '@redux-tools/react';

const configureStore = (preloadedState = {}, { restBaseUrl, wsBrokerUrl } = {}) => {
	const composeEnhancers = composeWithDevTools({
		name: 'UAMK partner portal',
		// NOTE: This option will allow us to see e.g. error payloads.
		serialize: true,
		// NOTE: Our project is Redux-heavy and seeing only the last 50 actions is insufficient.
		maxAge: 250,
		// NOTE: If not set, the extension might break upon injecting reducers.
		latency: 0,
		// NOTE: When `shouldHotReload` is set to `true`, all actions are "redispatched" upon `store.replaceReducer()`
		// which Redux Tools call under the hood. Some reducers react to these actions by updating the state,
		// causing a rerender of some components. When React components are created dynamically and are not memoized,
		// they may be completely remounted, causing a reinjection of a reducer, resulting in an infinite loop.
		// This behaviour is undesirable (i.e. noop rerenders shouldn't cause remounting of components),
		// but the infinite loops make these bugs very hard to track down. However, if `shouldHotReload` is `false`,
		// all actions prior to `store.replaceReducer()` will be erased from the Redux DevTools action log.
		// Developers are advised to change this from `true` to `false` locally if they encounter a development-only
		// infinite loop, and to fix the underlying cause.
		shouldHotReload: false,
	});

	const reducersEnhancer = makeReducersEnhancer();
	const middlewareEnhancer = makeMiddlewareEnhancer();
	const { injectedMiddleware } = middlewareEnhancer;

	const authenticationMiddleware = makeAuthenticationMiddleware();
	const persistorMiddleware = makePersistorMiddleware({ whitelistKeys: ['apium', 'auth'] });
	const apiumMiddleware = makeApiumMiddleware({ baseUrl: restBaseUrl });
	const websocketMiddleware = makeWebsocketMiddleware({ brokerURL: wsBrokerUrl });

	const store = createStore(
		// Identity simulates a redux reducer
		identity,
		preloadedState,
		composeEnhancers(
			reducersEnhancer,
			middlewareEnhancer,
			applyMiddleware(
				authenticationMiddleware,
				apiumMiddleware,
				persistorMiddleware,
				injectedMiddleware,
				websocketMiddleware,
				notificationsMiddleware
			)
		)
	);

	store.injectReducers({
		apium: apiumReducer,
		auth: authReducer,
		form: formReducer,
		modals: modalsReducer,
		navigation: navigationReducer,
		websockets: websocketsReducer,
		userNotifications: notificationReducer,
	});

	websocketMiddleware.initialize(store);

	return store;
};

export default configureStore;
