import { defineMessages } from 'react-intl';

export default defineMessages({
	navIncidents: {
		id: 'portal.navIncidents',
		defaultMessage: 'Incidents',
	},
	navUsers: {
		id: 'portal.navUsers',
		defaultMessage: 'Users',
	},
	navTechnics: {
		id: 'portal.navTechnics',
		defaultMessage: 'Technics',
	},
	navEconomics: {
		id: 'portal.navEconomics',
		defaultMessage: 'Economics',
	},
	navPricelists: {
		id: 'portal.navPricelists',
		defaultMessage: 'Pricelists',
	},
	navAuthors: {
		id: 'portal.navAuthors',
		defaultMessage: 'Authors',
	},
	navNotifications: {
		id: 'portal.navNotifications',
		defaultMessage: 'Notifications',
	},
	notificationShowDetailBtn: {
		id: 'notifications.notificationShowDetailBtn',
		defaultMessage: 'Show detail',
	},
	notificationViewAll: {
		id: 'portal.notificationViewAll',
		defaultMessage: 'View all notifications',
	},
});
