import React from 'react';
import { node } from 'prop-types';
import { Box, Grid } from 'lundium';

const TabContent = ({ children }) => (
	<Box className="tab-content">
		<Box className="section__inner">
			<Grid container>{children}</Grid>
		</Box>
	</Box>
);

TabContent.propTypes = {
	children: node,
	title: node,
};

export default TabContent;
