import React, { useState } from 'react';
import { bool, node, oneOfType, string } from 'prop-types';
import { Box, Icon, Text } from 'lundium';
import { cx } from 'ramda-extension';
import { FormattedMessage } from 'react-intl';

import m from '../../messages';

const SectionHeader = ({ buttons, content, isBottomContent, title = '', hasFilter }) => {
	const [isMobileFilterOpen, toggleMobileFilter] = useState(false);

	return (
		<Box className="section__heading">
			<Box className="section__heading-inner container">
				<Box className="section__title-wrapper">
					<Text as="h1" className="section__title" mb={0} mr={{ xs: 2, lg: 3 }}>
						{title}
					</Text>
				</Box>
				{content && (
					<Box
						className={cx('section__input-wrapper', {
							'pb-0': isMobileFilterOpen,
							'w-100': isBottomContent,
						})}
					>
						{hasFilter && (
							<Box
								as="span"
								className={cx('section__input-toggle-btn d-md-none', {
									'section__input-toggle-btn--open': isMobileFilterOpen,
								})}
								onClick={() => toggleMobileFilter(!isMobileFilterOpen)}
							>
								<FormattedMessage {...m.filter} />
								<Icon type="arrow_down" color="inherit" ml={2} />
							</Box>
						)}
						{content}
					</Box>
				)}
				{buttons && <Box className="section__btn-wrapper">{buttons}</Box>}
			</Box>
		</Box>
	);
};

SectionHeader.propTypes = {
	buttons: node,
	content: node,
	hasFilter: bool,
	isBottomContent: bool,
	title: oneOfType([string, node]),
};

export default SectionHeader;
