/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import { useAsyncDebounce } from 'react-table';
import { isNilOrEmpty, noop } from 'ramda-extension';
import { usePrevious } from '@uamk/utils';
import { equals } from 'ramda';

import { apiPaginationShape } from '../../constants';

/**
 *
 * Use only for tables with filters.
 * If you use this hook even for tables with no filters the fetch actions won`t be triggered due to isNilOrEmpty(filters) condition
 *
 * @param {*} param0
 * @returns
 */
const useFilteredTable = ({
	pagination = {},
	fetchResource = noop,
	filters,
	afterFetchAction = noop,
}) => {
	const [page, setPage] = useState(pagination?.pageNumber);
	const [pageSize, setPageSize] = useState(pagination?.pageSize);
	const [pageResetting, setPageResetting] = useState(false);
	const previousPageSize = usePrevious(pageSize);
	const previousFilters = usePrevious(filters);

	const debouncedFetchResource = useAsyncDebounce((value) => {
		fetchResource(value);
	}, 300);

	useEffect(() => {
		if (!isNilOrEmpty(filters) && !pageResetting) {
			fetchResource({
				page: pageSize !== previousPageSize && previousPageSize !== undefined ? 0 : page,
				size: pageSize,
				...filters,
			});
		}

		if (pageResetting) {
			setPageResetting(false);
		}

		if (pageSize !== previousPageSize && page !== 0 && previousPageSize !== undefined) {
			setPageResetting(true);
			setPage(0);
		}
	}, [page, pageSize, fetchResource]);

	useEffect(() => {
		// The condition !equals(filters, previousFilters) prevents double fetching.
		// Double fetching can happen because the useEffect is not able to detect changes in complex objects (eg. filters).
		// Sometimes the filter is 'changed' but not changed by delayed onBlur call on redux form input (SelectField).
		if (filters && previousFilters && !equals(filters, previousFilters)) {
			// this is a fix of double fetching (one debaunced, one  not debaunces in the effect above because page is changed).
			// It happens when current page is != 0 and page is reset to 0
			if (page !== 0) {
				setPageResetting(true);
				setPage(0);
			}
			// ............
			debouncedFetchResource({ page: 0, size: pageSize, ...filters });
			afterFetchAction();
		}
	}, [filters]);

	return {
		setPage,
		setPageSize,
	};
};

useFilteredTable.propTypes = {
	afterFetchAction: func,
	fetchResource: func,
	filters: object,
	pagination: apiPaginationShape,
};

export default useFilteredTable;
