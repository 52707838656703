import { defineMessages } from 'react-intl';

export default defineMessages({
	tokenInvalid: {
		id: 'auth.tokenInvalid',
		defaultMessage: 'Login error. Please contact UAMK.',
	},
	cannotRefresh: {
		id: 'auth.cannotRefresh',
		defaultMessage: 'Authentication expired. Please login again.',
	},
	apiError: {
		id: 'auth.apiError',
		defaultMessage: 'API call failed',
	},
	newPasswordSent: {
		id: 'auth.newPasswordSent',
		defaultMessage: 'New password was sent to {email}.',
	},
	passwordChanged: {
		id: 'auth.passwordChanged',
		defaultMessage: 'Password was successfuly changed.',
	},
	invalidGrant: {
		id: 'auth.invalidGrant',
		defaultMessage: 'Invalid login details.',
	},
	notFound: {
		id: 'auth.notFound',
		defaultMessage: 'Non-existent entity under ID.',
	},
	badCredentials: {
		id: 'auth.badCredentials',
		defaultMessage: 'Wrong password when changing password.',
	},
});
