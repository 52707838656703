import React from 'react';
import { configureStore } from '@uamk/store';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Notifications } from '@uamk/notifications';
import { getInitialState } from '@uamk/persistor';

import Router from './Router';
import { translations } from './messages';
import { restBaseUrl, wsBrokerUrl } from './constants';

const store = configureStore(getInitialState(), { restBaseUrl, wsBrokerUrl });

const locale = 'sk';

const App = () => (
	<IntlProvider locale={locale} messages={translations[locale]}>
		<Provider store={store}>
			<Router />
			<Notifications />
		</Provider>
	</IntlProvider>
);

export default App;
