import { defineMessages } from 'react-intl';

export default defineMessages({
	connectionErrorWeb: {
		id: 'websocket.connectionErrorWeb',
		defaultMessage: 'New incident feed not established.',
	},
	closeBtn: {
		id: 'websocket.closeBtn',
		defaultMessage: 'Close',
	},
});
