import { object, shape, string } from 'prop-types';
import { getLocaleDateTime } from '@uamk/utils';

const TableCellDateTime = ({ row: { values }, column: { id } }) => getLocaleDateTime(values[id]);

TableCellDateTime.propTypes = {
	column: shape({ id: string }),
	row: shape({ values: object }),
};

export default TableCellDateTime;
