import React, { useRef } from 'react';
import { isEmpty, o } from 'ramda';
import { cx } from 'ramda-extension';
import { any, bool, element, func, node, object, oneOfType, shape, string } from 'prop-types';
import { Box, Icon } from 'lundium';
import Flatpickr from 'react-flatpickr';
import { FormattedMessage } from 'react-intl';

import { withFormField, withGeneratedId } from '../../utils';
import { DatepickerOptions } from './constants';

export const DatepickerField = ({
	generatedId,
	label,
	disabled,
	input: { name, value, onFocus, onBlur },
	meta: { active, error, touched } = {},
	className,
	isRange,
	customOptions,
}) => {
	const flatpickrRef = useRef();
	const isCalendarOpen = flatpickrRef?.current?.flatpickr?.isOpen;

	return (
		<Box
			className={cx('form-group form-group--text has-suffix', className, {
				'is-focused': active || isCalendarOpen,
				'has-value': !isEmpty(value),
				'has-error': error && touched,
				disabled,
			})}
		>
			<label className="control-label" htmlFor={name || generatedId}>
				{label}
			</label>
			<Flatpickr
				ref={flatpickrRef}
				options={{
					...DatepickerOptions,
					mode: isRange ? 'range' : 'single',
					...customOptions,
				}}
			>
				<input
					disabled={disabled}
					autoComplete="off"
					type="text"
					className="form-control"
					data-input
					id={name}
					name={name}
					defaultValue={!isEmpty(value) ? value : null}
					onFocus={() => {
						onFocus();
					}}
					onBlur={(e) => {
						onBlur(e.target.value);
					}}
				/>
				<button type="button" className="input-group-append" data-toggle>
					<Icon type="date" color="primary" />
				</button>
			</Flatpickr>
			{error && touched && (
				<div className="invalid-feedback">
					<FormattedMessage {...error.message} values={error.messageValues} />
				</div>
			)}
		</Box>
	);
};

DatepickerField.propTypes = {
	className: string,
	customOptions: object,
	disabled: bool,
	generatedId: string,
	id: string,
	input: shape({
		name: string.isRequired,
		onBlur: func,
		onFocus: func,
		value: any,
	}),
	isRange: bool,
	label: oneOfType([string, node, object]),
	meta: any,
	suffix: oneOfType([string, element]),
	type: string,
};

export default o(withGeneratedId, withFormField)(DatepickerField);
